import exchange from "@/api/exchange";

const state = {
  sitemode: localStorage.getItem("SiteMode") || "trade",
  pair: localStorage.getItem("Pair") || "USDT_BTC",
  pairing: JSON.parse(localStorage.getItem("Pairing")) || null,
  depth: JSON.parse(localStorage.getItem("Depth")) || null,
  market: JSON.parse(localStorage.getItem("Market")) || null,
  trade: JSON.parse(localStorage.getItem("Trade")) || null
};

const getters = {
  getSitemode: state => state.sitemode,
  getPair: state => state.pair,
  getPairing: state => state.pairing,
  getDepth: state => state.depth,
  getMarket: state => state.market,
  getTrade: state => state.trade
};

const actions = {
  setSitemode({ commit }, mode) {
    localStorage.setItem("SiteMode", mode);
    commit("setSitemode", mode);
  },
  setPair({ commit }, pair) {
    localStorage.setItem("Pair", pair);
    commit("setPair", pair);
  },
  setPairing({ commit }, pairing) {
    localStorage.setItem("Pairing", JSON.stringify(pairing));
    commit("setPairing", pairing);
  },
  async fetchPairing({ commit }, pairing) {
    const response = await exchange.pairing(pairing);
    //ADD STAR MARKET
    if (!localStorage.getItem("StarMarket")) {
      var starmarket = {};
      for (const [key] of Object.entries(response.data)) {
        starmarket[key] = true;
      }
      localStorage.setItem("StarMarket", JSON.stringify(starmarket));
    } else {
      //ADD NEW LISTING PAIR
      var fabmarket = JSON.parse(localStorage.getItem("StarMarket"));
      for (const [key] of Object.entries(response.data)) {
        if (!fabmarket[key]) {
          fabmarket[key] = false;
        }
      }
      localStorage.setItem("StarMarket", JSON.stringify(fabmarket));
    }
    localStorage.setItem("Pairing", JSON.stringify(response.data));
    commit("fetchPairing", response.data);
  },
  setDepth({ commit }, depth) {
    localStorage.setItem("Depth", JSON.stringify(depth));
    commit("setDepth", depth);
  },
  async fetchDepth({ commit }, pair) {
    const response = await exchange.depth(pair);
    localStorage.setItem("Depth", JSON.stringify(response.data));
    commit("fetchDepth", response.data);
  },
  setMarket({ commit }, market) {
    localStorage.setItem("Market", JSON.stringify(market));
    commit("setMarket", market);
  },
  async fetchMarket({ commit }) {
    const response = await exchange.market();
    localStorage.setItem("Market", JSON.stringify(response.data));
    commit("fetchMarket", response.data);
  },
  setTrade({ commit }, trade) {
    localStorage.setItem("Trade", JSON.stringify(trade));
    commit("setTrade", trade);
  },
  async fetchTrade({ commit }, pair) {
    const response = await exchange.trade(pair);
    localStorage.setItem("Trade", JSON.stringify(response.data));
    commit("fetchTrade", response.data);
  }
};

const mutations = {
  setSitemode(state, sitemode) {
    state.sitemode = sitemode;
  },
  setPair(state, pair) {
    state.pair = pair;
  },
  setPairing(state, pairing) {
    state.pairing = pairing;
  },
  fetchPairing: (state, pairing) => (state.pairing = pairing),
  setDepth(state, depth) {
    state.depth = depth;
  },
  fetchDepth: (state, depth) => (state.depth = depth),
  setMarket(state, market) {
    state.market = market;
  },
  fetchMarket: (state, market) => (state.market = market),
  setTrade(state, trade) {
    state.trade = trade;
  },
  fetchTrade: (state, trade) => (state.trade = trade)
};

export default {
  state,
  getters,
  actions,
  mutations
};
