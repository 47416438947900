import api from "@/api/index";

export default {
  allusers(credentials) {
    return api().post("admin/allusers", credentials);
  },
  user(credentials) {
    return api().post("admin/user", credentials);
  },
  usertx(credentials) {
    return api().post("admin/usertx", credentials);
  },
  addmoney(credentials) {
    return api().post("admin/addmoney", credentials);
  },
  reset2fa(credentials) {
    return api().post("admin/reset2fa", credentials);
  },
  fundsummary(credentials) {
    return api().get("admin/fundsummary", credentials);
  },
  balancesummary(credentials) {
    return api().get("admin/balancesummary", credentials);
  },
  depositsummary(credentials) {
    return api().get("admin/depositsummary", credentials);
  },
  transactions(credentials) {
    return api().get("admin/transactions", credentials);
  },
  txrelation(credentials) {
    return api().post("admin/txrelation", credentials);
  },
  setting(credentials) {
    return api().get("setting", credentials);
  },
  updatesetting(credentials) {
    return api().post("setting/update", credentials);
  },
  currency(credentials) {
    return api().get("currency", credentials);
  },
  updatecurrency(credentials) {
    return api().post("currency/update", credentials);
  },
  alldeposit(credentials) {
    return api().get("alldeposit", credentials);
  },
  allwithdraw(credentials) {
    return api().get("allwithdraw", credentials);
  },
  updatewithdraw(credentials) {
    return api().post("withdraw/update", credentials);
  },
  sendtopool(credentials) {
    return api().post("deposit/sendtopool", credentials);
  },
  sendgas(credentials) {
    return api().post("deposit/sendgas", credentials);
  },
  delete(credentials) {
    return api().post("deposit/delete", credentials);
  },
  setpending(credentials) {
    return api().post("deposit/setpending", credentials);
  },
  announce(credentials) {
    return api().post("announce", credentials);
  },
  cardList(credentials) {
    return api().post("package/cardlist", credentials);
  },
  updatecard(credentials) {
    return api().post("package/card/update", credentials);
  },
  updateuser(credentials) {
    return api().post("user/update", credentials);
  },
  allkyc(credentials) {
    return api().get("all/kyc", credentials);
  },
  kycChangeStatus(credentials) {
    return api().post("kyc/changestatus", credentials);
  },
};
