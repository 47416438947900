import api from "@/api/index";

export default {
  market(credentials) {
    return api().get("/", credentials);
  },
  cancel(credentials) {
    return api().post("cancel", credentials);
  },
  cancelall(credentials) {
    return api().get("cancelall", credentials);
  },
  orderhistory(credentials) {
    return api().get("orderhistory", credentials);
  },
  openorders(credentials) {
    return api().get("openorders", credentials);
  },
  depth(pair, credentials) {
    return api().get("depth/" + pair, credentials);
  },
  trade(pair, credentials) {
    return api().get("trades/" + pair, credentials);
  },
  chart(pair, credentials) {
    return api().get("chart/" + pair, credentials);
  },
  pairing(credentials) {
    return api().get("pairing/", credentials);
  },
  coins(credentials) {
    return api().get("coins/", credentials);
  },
  submit(credentials) {
    return api().post("exchange/submit", credentials);
  }
};
