<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'REGISTER',
            icon: 'nc-icon nc-chart-pie-35',
            path: '/adminregister',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Users',
            icon: 'fa fa-user',
            path: '/adminusers',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/admincreateminingpool',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="m-3 mt-3">
        <div class="row">
          <div class="col-md-6">
            <card class="stacked-form" title="Register by admin">
              <div>
                <fg-input
                  label="Email address"
                  type="email"
                  placeholder="Enter email"
                  v-model="email"
                >
                </fg-input>
                <fg-input
                  type="password"
                  label="Passward"
                  placeholder="Enter Passward"
                  v-model="password"
                >
                </fg-input>
                <fg-input
                  type="password"
                  label="Confirm Passward"
                  placeholder="Confirm Passward"
                  v-model="confirmPass"
                >
                </fg-input>

                <center>
                  <button
                    @click="createAccount()"
                    style="background-color: green"
                    type="submit"
                    class="btn btn-fill btn-info"
                  >
                    Create Account
                  </button>
                </center>
              </div>
            </card>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import auth from "@/api/auth";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";

import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
  },
  data() {
    return {
      pricePer: "300.000",
      email: "",
      password: "",
      confirmPass: "",
      ref: "",
    };
  },
  methods: {
    async createAccount() {
      if (this.password == this.confirmPass && this.password != "") {
        await auth
          .signUp({
            email: this.email,
            password: this.password,
            ref: this.ref,
          })
          .then(() => {
            alert("เส็จแล้วจ่ะ");
          })
          .catch((error) => {
            alert(`${error.response.data.error}`);
          });
      } else {
        alert("รหัสผ่านไม่ตรงกัน");
      }
    },
  },
};
</script>
