<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">Please tell us more about yourself.</h5>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="name"
              :error="failed ? 'The First Name field is required' : null"
              :hasSuccess="passed"
              placeholder="First name and last name"
              v-model="name"
              label="First name"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <fg-input label="Gender">
        <div>
          <checkbox v-model="male" :inline="true">MALE</checkbox>
          <checkbox class="ml-2" v-model="female" :inline="true"
            >FEMALE</checkbox
          >
        </div>
      </fg-input>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="birthday"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              label="birthday"
              :error="failed ? 'The birthday field is required' : null"
              :hasSuccess="passed"
              v-model="birthday"
              placeholder="01/02/2559"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="passpot"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              label="International Passport / National ID card"
              :error="failed ? 'The Passport field is required' : null"
              :hasSuccess="passed"
              v-model="passport"
              placeholder="Passport"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="Address"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="Address"
              label="Address"
              v-model="address"
              :error="failed ? 'The Address field is required' : null"
              :hasSuccess="passed"
              placeholder="Address"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="Phone Number"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="Phone Number"
              label="Phone Number"
              v-model="phone"
              :error="failed ? 'The Phone Number field is required' : null"
              :hasSuccess="passed"
              placeholder="Phone Number"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="Nationality"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="Nationality"
              label="Nationality"
              v-model="nationality"
              :error="failed ? 'The Nationality field is required' : null"
              :hasSuccess="passed"
              placeholder="Nationality"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </form>
    <div>
      <div>
        <img style="width: 80%" src="static/img/upload_photo.png" alt="crash" />
      </div>
      <br />

      <div>
        <input
          @change="uploadImage($event)"
          type="file"
          accept="image/*"
          id="recfile"
          name="recfile"
        />
      </div>
    </div>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";
import { Select, Option, DatePicker } from "element-ui";
import { Checkbox, Radio } from "src/components/index";
import user from "@/api/user";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { API_URL } from "../../../../config";

extend("required", required);
extend("email", email);
extend("regex", regex);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    Checkbox,
    Radio,
  },

  props: {
    userKYC: Object,
  },

  data() {
    return {
      name: "",
      passport: "",
      address: "",
      phone: "",
      nationality: "",
      birthday: "",
      male: false,
      female: false,
      status: "wait",
      kyc_id: "",
      uploadImgID: "",
    };
  },
  computed: mapGetters(["getUser"]),
  methods: {
    async validate() {
      let sex = "";
      if (this.female == true) {
        sex = "female";
      } else {
        sex = "male";
      }
      if (this.female != false || this.male != false) {
        await user
          .kycCreate({
            user_id: this.getUser._id,
            email: this.getUser.email,
            name: this.name,
            passport: this.passport,
            address: this.address,
            phone: this.phone,
            nationality: this.nationality,
            birthday: this.birthday,
            gender: sex,
            status: this.status,
            kyc_id: this.kyc_id,
          })
          .then((res) => {
            return this.$refs.form.validate().then((res) => {
              this.$emit("on-validated", res);
              return res;
            });
          })
          .catch((err) => {
            return this.$refs.form.validate().then((res) => {
              this.$emit("on-validated", res);
              return res;
            });
          });

        return this.$refs.form.validate().then((res) => {
          this.$emit("on-validated", res);
          return res;
        });
      }
    },
    async uploadImage(event) {
      const URL = `${API_URL}/kyc/create/img`;
      let data = new FormData();
      data.append("file", event.target.files[0]);
      data.append("user_id", this.getUser._id);

      axios.post(URL, data).then((response) => {
        console.log("image upload response > ", response);
      });

      // if (this.uploadImgID == "") {

      // } else {
      //   data.append("img_id", this.uploadImgID);

      //   axios.post(URL, data, config).then((response) => {
      //     console.log("image upload response > ", response);
      //   });
      // }
    },
  },
  created() {
    if (this.userKYC != undefined || this.userKYC != null) {
      this.name = this.userKYC.name;
      this.passport = this.userKYC.passport;
      this.address = this.userKYC.address;
      this.phone = this.userKYC.phone;
      this.nationality = this.userKYC.nationality;
      this.birthday = this.userKYC.birthday;
      this.kyc_id = this.userKYC._id;
      if (this.userKYC.gender == "female") {
        this.female = true;
      } else {
        this.male = true;
      }
    }
  },
};
</script>
<style></style>
