import api from "@/api/index";

export default {
  onlineusers(credentials) {
    return api().get("onlineusers", credentials);
  },
  fee(credentials) {
    return api().get("fee", credentials);
  },
  coins(credentials) {
    return api().get("coins", credentials);
  },
  funds(currency, credentials) {
    return api().get("funds/" + currency, credentials);
  },
};
