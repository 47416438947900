<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="Change Password"></top-navbar>

      <div class="m-3 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <card class="stacked-form" title="">
              <a style="color: #14b3f9; font-size: 18px"> Change Password </a>
              <form method="#" action="#" @submit.prevent>
                <a style="font-size: 14px">
                  Weak passwords can easily lead to unauthorised access of your
                  account and loss of funds
                </a>
                <br />
                <br />
                <div>
                  <fg-input
                    label="Current Password"
                    v-model="currentPass"
                    placeholder="Current Password"
                  >
                  </fg-input>

                  <fg-input
                    v-model="newPass"
                    label="New Password"
                    placeholder="New Password"
                  >
                  </fg-input>

                  <fg-input
                    v-model="confirmPass"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                  >
                  </fg-input>
                  <br />

                  <center>
                    <div v-if="changePass">
                      <button
                        type="submit"
                        class="btn btn-fill btn-info"
                        disabled
                      >
                        Change Password
                      </button>
                    </div>
                    <div v-else>
                      <button
                        type="submit"
                        class="btn btn-fill btn-info"
                        @click="submit()"
                      >
                        Change Password
                      </button>
                    </div>
                  </center>
                </div>
              </form>
            </card>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
import user from "@/api/user";
import { API_URL } from "../../../config";
import investbox from "@/api/investbox";

import auth from "@/api/auth";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Radio,
  },
  data() {
    return {
      currentPass: "",
      newPass: "",
      confirmPass: "",
      changePass: false,
    };
  },
  computed: mapGetters(),
  methods: {
    ...mapActions(["Logout"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },

    async submit(hbx_coin) {
      if (
        this.currentPass == "" ||
        this.confirmPass == "" ||
        this.newPass == ""
      ) {
        Swal.fire({
          title: "Please input password",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-warning btn-fill",
        });
      } else {
        if (this.newPass == this.confirmPass) {
          await auth
            .updatePassword({
              old_password: this.currentPass,
              new_password: this.confirmPass,
              same_password: this.newPass,
            })
            .then((response) => {
              this.changePass = true;
              Swal.fire({
                title: "Change password succes. Please login",
                buttonsStyling: false,
                confirmButtonClass: "btn btn-fill btn-success",
              });
              this.Logout();
              this.$router.push("/login");
            })
            .catch((error) => {
              Swal.fire({
                title: error.response.data.error,
                buttonsStyling: false,
                confirmButtonClass: "btn btn-warning btn-fill",
              });
            });
        } else {
          Swal.fire({
            title: "Password is not match",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning btn-fill",
          });
        }
      }
    },
  },
  created() {},
};
</script>
