<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="m-1 mt-3">
        <div class="row">
          <div class="col-md-12">
            <card>
              <div slot="header">
                <h4 class="card-title" style="color: #14b3f9; font-weight: 500">
                  TRANSACTIONS
                </h4>
              </div>
              <div class="table-responsive table-full-width">
                <el-table class="table-striped" :data="table_rows">
                  <el-table-column property="createdAt"></el-table-column>
                  <el-table-column property="remark"></el-table-column>
                  <el-table-column property="currency"></el-table-column>
                  <el-table-column property="amount"></el-table-column>
                  <el-table-column property="type"></el-table-column>
                </el-table>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import user from "@/api/user";

import { Table, TableColumn } from "element-ui";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";

import investbox from "@/api/investbox";

import Swal from "sweetalert2";

import moment from "moment";

import admin from "@/api/admin";

export default {
  components: {
    TopNavbar,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      table_rows: [],
      email: this.$route.params.email || null,
    };
  },
  computed: mapGetters(["getUser"]),

  methods: {
    getdataAll() {
      admin
        .transactions()
        .then((res) => {
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            if (e.type != "INCOME") {
              this.table_rows.push(e);
            }
          });
        })
        .catch((error) => {});
    },
    getDataUser() {
      admin
        .usertx({ email: this.email })
        .then((res) => {
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.table_rows.push(e);
          });
        })
        .catch((error) => {});
    },
  },
  created() {
    if (this.email == null) {
      this.getdataAll();
    } else {
      this.getDataUser();
    }
  },
};
</script>
