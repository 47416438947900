<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver>
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="signIn()">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Login</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="email"
                      :error="failed ? 'The Email field is required' : null"
                      :hasSuccess="passed"
                      label="Email address"
                      name="email"
                      v-model="email"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="password"
                      :error="failed ? 'The Password field is required' : null"
                      :hasSuccess="passed"
                      name="password"
                      label="Password"
                      v-model="password"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <fg-input>
                    <l-checkbox v-model="subscribe"> Save Password </l-checkbox>
                  </fg-input>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-fill btn-info btn-round btn-wd"
                  >
                    Login
                  </button>
                  <br />
                  <div class="forgot">
                    <router-link to="/forgotpassword" class="card-category">
                      Forgot your password?
                    </router-link>
                  </div>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

import { SITE_URL, RECPATCHA_KEY } from "../../../config";
import nativeToast from "native-toast";
import auth from "@/api/auth";
import { mapGetters, mapActions } from "vuex";

import { load } from "recaptcha-v3";

import Swal from "sweetalert2";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
    factor: "",
    token: null,
    loading: false,
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      factor: "",
    };
  },
  computed: mapGetters(["getToken", "getUser"]),

  methods: {
    ...mapActions([
      "setToken",
      "setUser",
      "setSecure",
      "fetchBalance",
      "setBalance",
    ]),

    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    asyncFunction() {
      load(RECPATCHA_KEY).then((recaptcha) => {
        recaptcha.execute("login").then((token) => {
          this.token = token;
        });
      });
    },
    async signIn() {
      Swal.fire({
        title: "Risk",
        html:
          "<div>" +
          "Usability contains risk. We are not promising returns, income or profit. Investment contain risk  like price risk , platform risk and others risky situations. Asset invested increase , decrease or down to zero users must study all information before investing" +
          "<br />  <a href='/terms' target='_blink'>Terms & Conditions</a>" +
          "</div>",

        type: "warning",
        confirmButtonText: "Accepted",
        confirmButtonClass: "btn btn-success btn-fill",
        buttonsStyling: false,
      }).then((value) => {
        if (value.isConfirmed == true) {
          this.signUpUser();
        }
      });
    },
    async signUpUser() {
      await auth
        .signIn({
          email: this.email,
          password: this.password,
          twofa: this.factor,
          token: this.token,
        })
        .then(async (response) => {
          if (response.data.email) {
            this.$router.push(`/managepool`);
          } else {
            this.setToken(response.data.token);
            this.setUser(response.data.user);
            this.setSecure(response.data.secure);
            // this.fetchBalance();
            this.$router.push("/managepool");
          }
        })
        .catch((error) => {
          Swal.fire({
            title: `${error.response.data.error}`,
            text: `${error.response.data.error}`,
            timer: 2000,
            showConfirmButton: false,
          });
          this.asyncFunction();
        });
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  created() {
    Swal.fire({
      title: `Announce`,
      text: `To support the mining system in the near future
              and better usability
              The company has developed a new Upgrade Website for the whole system.
              All members can Active Verify their identity.
              to access the account
              *Notification of registration to verify new system identity
              Using the same E-mal at Line : @hashbx`,
      type: "success",
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false,
    });
    this.asyncFunction();
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
