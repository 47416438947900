/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.3.1
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import LightBootstrap from "./light-bootstrap-main";
// Plugins
import App from "./App.vue";
import store from "./store";

// router setup
import router from "./routes/routes";
// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
