<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item> -->

        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="Summary"></top-navbar>

      <div class="m-1">
        <div class="mt-3">
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div
                style="
                  background-color: rgb(28, 43, 54);
                  color: white;
                  border-style: solid;
                  border-color: #14b3f9;
                "
              >
                <div class="row">
                  <div class="col-10">
                    <div class="p-3">
                      <a style="font-size: 20px"
                        >BTC/USD {{ priceBTCUSDT }} $</a
                      >
                      <br />
                      <a style="font-size: 14px">Price 24 hr (Binance) </a>
                    </div>
                  </div>
                </div>
                <br />
              </div>
              <div class="row">
                <div class="col-xl-4 mt-2">
                  <div class="row">
                    <div class="col-6">
                      <div
                        style="
                          width: 110%;
                          background-color: rgb(28, 43, 54);
                          color: #c2c4c7;
                          border-radius: 5px;
                          border-style: solid;
                          border-color: #14b3f9;
                        "
                      >
                        <center>
                          <div class="pt-3 pb-3">
                            <a style="font-size: 14px; font-weight: 600">
                              TOTAL LEFT
                            </a>
                            <br />
                            <a
                              style="
                                font-size: 22px;
                                font-weight: 600;
                                color: white;
                              "
                            >
                              2,399,257
                            </a>
                            <br />
                            <a style="font-size: 14px; font-weight: 600">
                              BTC
                            </a>
                          </div>
                        </center>
                      </div>
                    </div>
                    <div class="col-6">
                      <div
                        style="
                          width: 100%;
                          background-color: rgb(28, 43, 54);
                          color: #c2c4c7;
                          border-radius: 5px;
                          border-style: solid;
                          border-color: #14b3f9;
                        "
                      >
                        <center>
                          <div class="pt-3 pb-3">
                            <a style="font-size: 14px; font-weight: 600">
                              CIRCULATION
                            </a>
                            <br />
                            <a
                              style="
                                font-size: 22px;
                                font-weight: 600;
                                color: white;
                              "
                            >
                              18,600,743
                            </a>
                            <br />
                            <a style="font-size: 14px; font-weight: 600">
                              BTC
                            </a>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 mt-2">
                  <div class="row">
                    <div class="col-6">
                      <div
                        style="
                          width: 110%;
                          background-color: rgb(28, 43, 54);
                          color: #c2c4c7;
                          border-radius: 5px;
                          border-style: solid;
                          border-color: #14b3f9;
                        "
                      >
                        <center>
                          <div class="pt-3 pb-3">
                            <a style="font-size: 14px; font-weight: 600">
                              GLOBAL HASHRATE
                            </a>
                            <br />
                            <a
                              style="
                                font-size: 22px;
                                font-weight: 600;
                                color: white;
                              "
                            >
                              150,562,000
                            </a>
                            <br />
                            <a style="font-size: 14px; font-weight: 600">
                              TH/s
                            </a>
                          </div>
                        </center>
                      </div>
                    </div>
                    <div class="col-6">
                      <div
                        style="
                          width: 100%;
                          background-color: rgb(28, 43, 54);
                          color: #c2c4c7;
                          border-radius: 5px;
                          border-style: solid;
                          border-color: #14b3f9;
                        "
                      >
                        <center>
                          <div class="pt-3 pb-3">
                            <a style="font-size: 14px; font-weight: 600">
                              ALL MEMBERS
                            </a>
                            <br />
                            <a
                              style="
                                font-size: 22px;
                                font-weight: 600;
                                color: white;
                              "
                            >
                              51,984+
                            </a>
                            <br />
                            <a style="font-size: 14px; font-weight: 600">
                              Account
                            </a>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 mt-2">
                  <div class="row">
                    <div class="col-6">
                      <div
                        style="
                          width: 110%;
                          background-color: rgb(28, 43, 54);
                          color: #c2c4c7;
                          border-radius: 5px;
                          border-style: solid;
                          border-color: #14b3f9;
                        "
                      >
                        <center>
                          <div class="pt-3 pb-3">
                            <a style="font-size: 14px; font-weight: 600">
                              ANTPOOL HASHRATE
                            </a>
                            <br />
                            <a
                              style="
                                font-size: 22px;
                                font-weight: 600;
                                color: white;
                              "
                            >
                              15,100,000
                            </a>
                            <br />
                            <a style="font-size: 14px; font-weight: 600">
                              TH/s
                            </a>
                          </div>
                        </center>
                      </div>
                    </div>
                    <div class="col-6">
                      <div
                        style="
                          width: 100%;
                          background-color: rgb(28, 43, 54);
                          color: #c2c4c7;
                          border-radius: 5px;
                          border-style: solid;
                          border-color: #14b3f9;
                        "
                      >
                        <center>
                          <div class="pt-3 pb-3">
                            <a style="font-size: 14px; font-weight: 600">
                              BTC SUPPLY
                            </a>
                            <br />
                            <a
                              style="
                                font-size: 22px;
                                font-weight: 600;
                                color: white;
                              "
                            >
                              1,757+
                            </a>
                            <br />
                            <a style="font-size: 14px; font-weight: 600">
                              BTC
                            </a>
                          </div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div style="border-style: solid; border-color: #14b3f9">
                <VueTradingView
                  :options="{
                    width: '100%',
                    symbol: 'BINANCE:BTCUSDT',
                    interval: 'D',
                    theme: 'dark',
                    style: '1',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import VueTradingView from "vue-trading-view";

import { mapGetters, mapActions } from "vuex";
import axios from "axios";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu,
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
    VueTradingView,
  },
  data() {
    return {
      priceBTCUSDT: "",
    };
  },
  computed: mapGetters(["getUser"]),
  methods: {
    ...mapActions(["Logout"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },
  },
  created() {
    setInterval(() => {
      axios
        .get(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
        .then((response) => {
          this.priceBTCUSDT = parseFloat(response.data.price).toFixed(2);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 15000);
    axios
      .get(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
      .then((response) => {
        this.priceBTCUSDT = parseFloat(response.data.price).toFixed(2);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
