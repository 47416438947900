<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="m-1 mt-3">
        <div class="m-3">
          <div class="row justify-content-center">
            <div
              style="background-color: #dbe7f5"
              class="col-md-4 m-2"
              v-for="fun in funds"
              :key="fun"
            >
              <div v-if="fun.currency != 'undefined'">
                <a>
                  <img
                    style="max-width: 40px; margin-top: 10px"
                    :src="require(`@/assets/tokens/${fun.currency}.png`)"
                  />
                  <a style="font-weight: 600">
                    {{ fun.currency }}
                  </a>
                </a>
                <div class="m-1">
                  <a style="font-size: 14px">
                    <a v-if="fun.currency == 'BTC'">
                      Amount: {{ parseFloat(fun.amount).toFixed(8) }}
                    </a>
                    <a v-else>
                      Amount: {{ parseFloat(fun.amount).toFixed(2) }}</a
                    >
                    <br />
                    <a v-if="fun.currency == 'S9THs'">
                      In Mining: {{ parseFloat(in_mining).toFixed(2) }}
                    </a>
                    <a v-else>
                      In Mining: {{ parseFloat(fun.staking).toFixed(2) }}
                    </a>
                    <br />
                    <a v-if="fun.currency == 'S9THs'">
                      In Farming: {{ parseFloat(in_fram).toFixed(2) }}
                    </a>
                    <a v-else>
                      In Farming: {{ parseFloat(fun.staking).toFixed(2) }}
                    </a>
                    <br />
                    <a v-if="fun.currency == 'S9THs'"
                      >Balance:
                      <a style="font-weight: 600">
                        {{
                          (
                            parseFloat(fun.balance) +
                            parseFloat(in_fram) +
                            parseFloat(in_mining)
                          ).toFixed(2)
                        }}</a
                      >
                    </a>
                    <a v-else-if="fun.currency == 'BTC'">
                      Balance:
                      <a style="font-weight: 600">
                        {{ parseFloat(fun.balance).toFixed(8) }}</a
                      >
                    </a>
                    <a v-else>
                      Balance:
                      <a style="font-weight: 600">
                        {{ parseFloat(fun.balance).toFixed(2) }}</a
                      ></a
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import user from "@/api/user";

import { Table, TableColumn } from "element-ui";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";

import investbox from "@/api/investbox";

import Swal from "sweetalert2";

import moment from "moment";

import admin from "@/api/admin";

export default {
  components: {
    TopNavbar,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      funds: [],
      ready: false,
      in_mining: 0,
      in_fram: 0,
    };
  },
  computed: mapGetters(["getUser"]),

  methods: {
    fixed(a) {
      return parseFloat(a).toFixed(8);
    },
    getData() {
      admin.fundsummary().then((res) => {
        res.data.forEach((e) => {
          this.funds.push(e);
        });
        this.ready = true;
      });
    },
    getListAll() {
      investbox
        .listAll()
        .then((res) => {
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            if (e.status != "COMPLETED") {
              if (e.type == "FARMING") {
                this.in_fram = parseFloat(this.in_fram) + parseFloat(e.amount);
              } else if (e.type == "MINING") {
                this.in_mining =
                  parseFloat(this.in_mining) + parseFloat(e.amount);
              }
            }
          });
        })
        .catch((error) => {});
    },
  },
  created() {
    this.getData();
    this.getListAll();
  },
};
</script>
