<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="m-1 mt-3">
        <div class="row">
          <div class="col-md-12">
            <card>
              <div slot="header">
                <h4 class="card-title">Users</h4>
                <p class="card-category">All User KYC</p>
              </div>
              <form @change="changeRadio()">
                <div class="row">
                  <div class="col-3">
                    <div class="row justify-content-end">
                      <radio label="all" v-model="radios.radio1"
                        >All List
                      </radio>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="row justify-content-center">
                      <radio label="wait" v-model="radios.radio1"
                        >Wait List</radio
                      >
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="row justify-content-center">
                      <radio label="reject" v-model="radios.radio1"
                        >Reject List</radio
                      >
                    </div>
                  </div>
                  <div class="col-3">
                    <radio label="approve" v-model="radios.radio1"
                      >Approve List</radio
                    >
                  </div>
                </div>
              </form>

              {{ radios.radio1 }}

              <div class="table-responsive table-full-width">
                <el-table class="table-striped" :data="users">
                  <el-table-column
                    label="createdAt"
                    property="createdAt"
                  ></el-table-column>
                  <el-table-column
                    label="email"
                    property="email"
                  ></el-table-column>
                  <el-table-column label="name" property="name">
                  </el-table-column>
                  <el-table-column label="Status">
                    <template class="td-actions" slot-scope="props">
                      {{ props.row.status }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Actions">
                    <div class="td-actions" slot-scope="props">
                      <a
                        v-tooltip.top-center="'View Profile'"
                        class="btn btn-info btn-link btn-xs"
                        :href="'/adminprofile/' + props.row.user_id"
                        target="_blink"
                      >
                        <i class="fa fa-user"></i>
                      </a>
                      <a
                        v-tooltip.top-center="'Edit Profile'"
                        class="btn btn-warning btn-link btn-xs"
                        :href="'/adminprofile/' + props.row.user_id"
                      >
                        <i class="fa fa-edit"></i>
                      </a>
                      <a
                        v-tooltip.top-center="'Delete Profile KYC'"
                        class="btn btn-danger btn-link btn-xs"
                      >
                        <i class="fa fa-close"></i>
                      </a>
                    </div>
                  </el-table-column>
                </el-table>
              </div>
            </card>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import user from "@/api/user";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";

import admin from "@/api/admin";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Radio,
  },
  data() {
    return {
      users: [],
      id: "",
      user: {},
      radios: {
        radio1: "all",
      },
    };
  },
  computed: mapGetters(["getUser"]),

  methods: {
    ...mapActions([]),
    tableRowClassName(row, index) {
      if (index === 0) {
        return "success";
      } else if (index === 2) {
        return "info";
      } else if (index === 4) {
        return "danger";
      } else if (index === 6) {
        return "warning";
      }
      return "";
    },

    changeRadio() {
      this.users = [];
      if (this.radios.radio1 == "all") {
        this.getUsersList();
      } else if (this.radios.radio1 == "approve") {
        this.getUserApprove();
      } else if (this.radios.radio1 == "wait") {
        this.getUserWait();
      } else if (this.radios.radio1 == "reject") {
        this.getUserReject();
      }
    },
    async getUsersList() {
      await admin.allkyc().then((res) => {
        this.users = res.data.dataKYC;
      });
    },

    async getUserWait() {
      await admin.allkyc().then((res) => {
        this.users = res.data.dataKYC.filter((u) => {
          return u.status == "wait";
        });
      });
    },
    async getUserApprove() {
      await admin.allkyc().then((res) => {
        this.users = res.data.dataKYC.filter((u) => {
          return u.status == "approve";
        });
      });
    },
    async getUserReject() {
      await admin.allkyc().then((res) => {
        this.users = res.data.dataKYC.filter((u) => {
          return u.status == "reject";
        });
      });
    },
  },
  created() {
    this.getUsersList();
  },
};
</script>
