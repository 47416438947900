const toFixedd = (num, fixed) => {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
};

export default {
  fixed(a) {
    a = a / 1;
    if (a < 0.00000001 && a > -0.00000001) {
      return "0.00000000";
    }
    if (a >= 1000) {
      return parseFloat(a).toLocaleString();
    } else if (a >= 100) {
      return toFixedd(a, 5);
    } else if (a >= 10) {
      return toFixedd(a, 6);
    } else if (a >= -10) {
      return toFixedd(a, 7);
    } else if (a >= -100) {
      return toFixedd(a, 6);
    } else if (a >= -1000) {
      return toFixedd(a, 5);
    } else if (a >= -10000) {
      return toFixedd(a, 4);
    } else if (a >= -100000) {
      return toFixedd(a, 3);
    } else if (a >= -1000000) {
      return toFixedd(a, 2);
    } else if (a < -1000000) {
      return toFixedd(a, 1);
    } else {
      return toFixedd(a, 8);
    }
  },
  fixedNum(a) {
    a = a / 1;
    if (a < 0.00000001 && a > -0.00000001) {
      return "0.00000000";
    }
    if (a >= 1000000) {
      return toFixedd(a, 1);
    } else if (a >= 100000) {
      return toFixedd(a, 2);
    } else if (a >= 10000) {
      return toFixedd(a, 3);
    } else if (a >= 1000) {
      return toFixedd(a, 4);
    } else if (a >= 100) {
      return toFixedd(a, 5);
    } else if (a >= 10) {
      return toFixedd(a, 6);
    } else if (a >= -10) {
      return toFixedd(a, 7);
    } else if (a >= -100) {
      return toFixedd(a, 6);
    } else if (a >= -1000) {
      return toFixedd(a, 5);
    } else if (a >= -10000) {
      return toFixedd(a, 4);
    } else if (a >= -100000) {
      return toFixedd(a, 3);
    } else if (a >= -1000000) {
      return toFixedd(a, 2);
    } else if (a < -1000000) {
      return toFixedd(a, 1);
    } else {
      return toFixedd(a, 8);
    }
  },
  fixed8(a) {
    a = a / 1;
    return parseFloat(Math.floor(a * 100000000) / 100000000)
      .toFixed(8)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  },
  fixed2(a) {
    a = a / 1;
    return parseFloat(Math.floor(a * 100000000) / 100000000)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  },
  fixed4(a) {
    a = a / 1;
    return parseFloat(Math.floor(a * 100000000) / 100000000)
      .toFixed(4)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }
};
