<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver>
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="resetPassword()">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Forgot Password</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="email"
                      :error="failed ? 'The Email field is required' : null"
                      :hasSuccess="passed"
                      label="Email address"
                      name="email"
                      v-model="email"
                    >
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <a v-if="sendEmail">
                    <button
                      type="submit"
                      class="btn btn-fill btn-info btn-round btn-wd"
                      disabled
                    >
                      Reset Password
                    </button>
                  </a>
                  <a v-else>
                    <button
                      type="submit"
                      class="btn btn-fill btn-info btn-round btn-wd"
                    >
                      Reset Password
                    </button>
                  </a>

                  <br />
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

import { SITE_URL, RECPATCHA_KEY } from "../../../config";
import nativeToast from "native-toast";
import { mapGetters, mapActions } from "vuex";

import { load } from "recaptcha-v3";

import auth from "@/api/auth";

import Swal from "sweetalert2";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
    factor: "",
    token: null,
    loading: false,
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      factor: "",
      sendEmail: false,
    };
  },
  computed: mapGetters(["getToken", "getUser"]),

  methods: {
    ...mapActions([
      "setToken",
      "setUser",
      "setSecure",
      "fetchBalance",
      "setBalance",
    ]),

    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    resetPassword() {
      this.loading = true;
      auth
        .resetPassword({ email: this.email })
        .then(() => {
          this.sendEmail = true;
          // this.sendEmailSuccess();
          this.$router.push("/resetpasswordverification");
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    sendEmailSuccess() {
      Swal.fire({
        title: "Please check password from email",
        type: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
      });
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  created() {},
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
