import investbox from "@/api/investbox";

const state = {
  invest: null,
  allinvest: null,
};

const getters = {
  getInvest: (state) => state.invest,
  getAllInvest: (state) => state.allinvest,
};

const actions = {
  async fetchInvest({ commit }) {
    const response = await investbox.list();
    localStorage.setItem("InvestBox", JSON.stringify(response.data));
    commit("fetchInvest", response.data);
  },
  async fetchAllInvest({ commit }) {
    const response = await investbox.list();
    localStorage.setItem("AllInvestBox", JSON.stringify(response.data));
    commit("fetchAllInvest", response.data);
  },
  setInvest({ commit }, data) {
    commit("setInvest", data);
  },
};

const mutations = {
  fetchInvest: (state, invest) => (state.invest = invest),
  fetchAllInvest: (state, allinvest) => (state.allinvest = allinvest),
  setInvest: (state, invest) => (state.invest = invest),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
