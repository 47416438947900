<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="m-1 mt-3">
        <div class="row">
          <div class="col-md-10">
            <card>
              <div slot="header">
                <h4 class="card-title">Users</h4>
                <p class="card-category">All User</p>
              </div>
              <div class="table-responsive table-full-width">
                <el-table class="table-striped" :data="users">
                  <el-table-column label="ID" property="_id"></el-table-column>
                  <el-table-column label="" property="email"></el-table-column>
                  <el-table-column
                    label="Credit"
                    property="credit"
                  ></el-table-column>
                  <el-table-column
                    label="Rebate"
                    property="rebate"
                  ></el-table-column>
                  <el-table-column
                    label="P bonus"
                    property="pool_bonus"
                  ></el-table-column>
                  <el-table-column
                    label="P hash"
                    property="power_hash"
                  ></el-table-column>
                </el-table>
              </div>
            </card>
          </div>
          <div class="col-md-2">
            <card class="stacked-form">
              <center>
                <i
                  class="fa fa-user"
                  style="font-size: 52px; color: orange"
                ></i>
                <br />
                <a style="font-size: 24px; font-weight: 600">
                  <a style="font-size: 14px">Users</a>
                </a>
              </center>

              <div>
                <fg-input
                  v-model="id"
                  label="ID User"
                  type="email"
                  placeholder="Enter id"
                >
                </fg-input>

                <center>
                  <button
                    @click="searchUser()"
                    style="background-color: green"
                    class="btn btn-fill btn-info"
                  >
                    Search
                  </button>
                </center>
              </div>
            </card>
            <card class="stacked-form">
              <fg-input
                v-model="user.email"
                label="email"
                type="email"
                placeholder="Enter id"
                disabled
              >
              </fg-input>

              <div>
                <fg-input
                  v-model="amountCredit"
                  label="Credit"
                  placeholder="amount credit"
                  disabled
                >
                </fg-input>

                <fg-input
                  v-model="amountCreditAdd"
                  label="Add Credit"
                  placeholder="add amount credit"
                  type="Number"
                >
                </fg-input>

                <fg-input
                  v-model="amountRebate"
                  label="Rebate"
                  placeholder="amount Rebate"
                  disabled
                >
                </fg-input>
                <fg-input
                  v-model="amountRebateAdd"
                  label="Add Rebate"
                  placeholder="Add amount Rebate"
                  type="Number"
                >
                </fg-input>

                <fg-input
                  v-model="amountPoolBonus"
                  label="Pool Bonus"
                  placeholder="Amount Pool Bonus"
                  disabled
                >
                </fg-input>
                <fg-input
                  v-model="amountPoolBonusAdd"
                  label="Add Pool Bonus"
                  placeholder="Add Amount Pool Bonus"
                  type="Number"
                >
                </fg-input>

                <fg-input
                  v-model="amountPowerHash"
                  label="Pawaer Hash"
                  placeholder="amount Power hash"
                  disabled
                >
                </fg-input>
                <fg-input
                  v-model="amountPowerHashAdd"
                  label="Add Pawaer Hash"
                  type="Number"
                  placeholder="Add amount Power hash"
                >
                </fg-input>

                <center>
                  <button @click="update()" class="btn btn-info">Update</button>
                </center>
              </div>
            </card>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import user from "@/api/user";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";
import Swal from "sweetalert2";

import admin from "@/api/admin";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      users: [],
      id: "",
      user: {},
      amountCredit: "",
      amountPoolBonus: "",
      amountRebate: "",
      amountPowerHash: "",
      amountCreditAdd: "0",
      amountPoolBonusAdd: "0",
      amountRebateAdd: "0",
      amountPowerHashAdd: "0",
    };
  },
  computed: mapGetters(["getUser"]),

  methods: {
    ...mapActions([]),

    tableRowClassName(row, index) {
      if (index === 0) {
        return "success";
      } else if (index === 2) {
        return "info";
      } else if (index === 4) {
        return "danger";
      } else if (index === 6) {
        return "warning";
      }
      return "";
    },
    async getUsersList() {
      await user.usersList().then((res) => {
        this.users = res.data.users;
      });
    },
    searchUser() {
      if (this.id != "") {
        axios
          .get(`${API_URL}/user/detail/${this.id}`)
          .then((response) => {
            this.user = response.data.users;
            this.amountBTC = this.user.btc;

            this.amountCredit = this.user.credit;
            this.amountPoolBonus = this.user.pool_bonus;
            this.amountRebate = this.user.rebate;
            this.amountPowerHash = this.user.power_hash;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert("ใส่ข้อมูลก่อน");
      }
    },
    async update() {
      if (this.id != "") {
        await admin
          .updateuser({
            _id: this.id,
            credit: this.amountCreditAdd,
            pool_bonus: this.amountPoolBonusAdd,
            rebate: this.amountRebateAdd,
            power_hash: this.amountPowerHashAdd,
          })
          .then(() => {
            this.getUsersList();
            this.searchUser();

            this.amountCreditAdd = "";
            this.amountPoolBonusAdd = "";
            this.amountRebateAdd = "";
            this.amountPowerHashAdd = "";

            Swal.fire({
              title: `Success`,
              text: `Update Success!`,
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            alert("err");
          });
      }
    },
  },
  created() {
    this.getUsersList();
  },
};
</script>
