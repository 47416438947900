<template>
  <div>
    <auth-layout pageClass="register-page">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10">
          <a
            target="_blink"
            href="https://pancakeswap.finance/swap?outputCurrency=0x7b674129d7ce4b17d7d69c4c68a00aa429230e88"
          >
            <l-button round style="background-color: #d5990c"
              >HBX | PANCAKESWAP</l-button
            >&nbsp;
          </a>

          <a
            target="_blink"
            href="https://charts.bogged.finance/?token=0x7b674129D7cE4b17D7D69c4c68A00aa429230e88"
          >
            <l-button type="primary" round>HBX | CHART</l-button>&nbsp;
          </a>

          <br />
          <a
            target="_blink"
            href="https://www.dextools.io/app/bsc/pair-explorer/0x43943814034fc2777eb44d8a56e4e45873ccfe32"
          >
            <l-button round style="background-color: #23ba2f"
              >HBX | BSC SCAN</l-button
            >
          </a>
          <a
            target="_blink"
            href="https://hashbx.web.app/static/pdf/HashBX-White-Paper-2020.pdf"
          >
            <l-button round style="background-color: #187FA9"
              >White Paper</l-button
            >
          </a>

          <br />
          <a style="color: white; font-size: 12px">
            0x7b674129d7ce4b17d7d69c4c68a00aa429230e88
          </a>
          <a
            href="#"
            @click="copyText('0x7b674129d7ce4b17d7d69c4c68a00aa429230e88')"
          >
            <i class="fa fa-clone" style="font-size: 16px; color: white"></i>
          </a>

          <div class="header-text">
            <h1 style="font-weight: 600">The easiest way to mine Bitcoins</h1>
            <p style="font-size: 24px">
              <a style="font-weight: 600; color: #d5990c">18,600,743 BTC</a> of
              Bitcoin in Circulating Supply.
            </p>
          </div>
        </div>
        <div class="col-md-10">
          <div class="header-text">
            <a>
              Bitcoin mining has many factors involved , like price of bitcoin
              that contain fluctuations and mining of bitcoin includes
              difficulties which increase and decrease all the time. The average
              cost is increasing every 15 days which make mining rewards
              decrease and due to halving is happening make mining reward
              reduced into half in every 4 years from block 12.5 to 6.25 which
              make mining reward for miners gone lesser Due to present mining
              will not worth the cost to protect problem of auto collection of
              electricity cost the system is now paused during the time of not
              worth mining
            </a>
          </div>
        </div>

        <div class="col-md-6">
          <fade-render-transition :duration="200">
            <div class="media">
              <div class="media-left">
                <div class="icon">
                  <i class="nc-icon nc-circle-09"></i>
                </div>
              </div>
              <div class="media-body">
                <h4>Price {{ priceBTCUSDT }} USD/BTC</h4>
                <p>
                  Market Prices and exchanges rates, Referenced by Binance is
                  update in minutes
                </p>
                <a
                  class="ml-3"
                  target="_blink"
                  href="https://www.binance.com/en"
                  >Learn More</a
                >
              </div>
            </div>
          </fade-render-transition>
          <fade-render-transition :delay="200" :duration="200">
            <div class="media">
              <div class="media-left">
                <div class="icon">
                  <i class="nc-icon nc-preferences-circle-rotate"></i>
                </div>
              </div>
              <div class="media-body">
                <h4>Our power 15.1 EH/s</h4>
                <p>
                  At the moment the total power of Antpool about 15,100,000
                  TH/s.
                </p>
                <a
                  class="ml-3"
                  target="_blink"
                  href="https://v3.antpool.com/home"
                  >Learn More</a
                >
              </div>
            </div>
          </fade-render-transition>
          <fade-render-transition :delay="400" :duration="200">
            <div class="media">
              <div class="media-left">
                <div class="icon">
                  <i class="nc-icon nc-planet"></i>
                </div>
              </div>
              <div class="media-body">
                <h4>More than 5 year operated ( since 2016 )</h4>
                <p>We have been operating for over 5 years.</p>
                <a class="ml-3" href="#">Learn More</a>
              </div>
            </div>
          </fade-render-transition>
        </div>
      </div>
    </auth-layout>

    <div class="row justify-content-center">
      <div class="col-md-10 mt-5">
        <center>
          <h2 style="font-weight: 600">Quick start for 60 seconds</h2>
        </center>
        <div class="row justify-content-center">
          <div class="col-md-4 mt-3">
            <center>
              <a
                style="
                  border-radius: 50px;
                  border-color: #9e9b9b;
                  border-style: solid;
                  border-width: 10px;
                  width: 100px;
                  height: 100px;
                "
                class="d-flex align-items-center justify-content-center"
              >
                <div class="ml-n2">
                  <i class="fa fa-user-plus" style="font-size: 32px"></i>
                </div>
              </a>
              <p class="mt-3" style="font-size: 20px; font-weight: 600">
                Create your user account
              </p>
              <a>
                Use your email to create a new account. We will use this address
                to send access details.
              </a>
            </center>
          </div>
          <div class="col-md-4 mt-3">
            <center>
              <a
                style="
                  border-radius: 50px;
                  border-color: #9e9b9b;
                  border-style: solid;
                  border-width: 10px;
                  width: 100px;
                  height: 100px;
                "
                class="d-flex align-items-center justify-content-center"
              >
                <div class="ml-n3">
                  <i class="fa fa-envelope-open" style="font-size: 32px"></i>
                </div>
              </a>
              <p class="mt-3" style="font-size: 20px; font-weight: 600">
                Check your email
              </p>
              <a>
                You will receive login and password to access the service.
              </a>
            </center>
          </div>
          <div class="col-md-4 mt-3">
            <center>
              <a
                style="
                  border-radius: 50px;
                  border-color: #9e9b9b;
                  border-style: solid;
                  border-width: 10px;
                  width: 100px;
                  height: 100px;
                "
                class="d-flex align-items-center justify-content-center"
              >
                <div class="ml-n1">
                  <i class="fa fa-lock" style="font-size: 32px"></i>
                </div>
              </a>
              <p class="mt-3" style="font-size: 20px; font-weight: 600">
                Log in
              </p>
              <a>
                Use login and password that have been sent on your email address
                and log in the service.
              </a>
            </center>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-4 mt-3">
            <center>
              <a
                style="
                  border-radius: 50px;
                  border-color: #9e9b9b;
                  border-style: solid;
                  border-width: 10px;
                  width: 100px;
                  height: 100px;
                "
                class="d-flex align-items-center justify-content-center"
              >
                <div class="ml-n2">
                  <i class="fa fa-rocket" style="font-size: 32px"></i>
                </div>
              </a>
              <p class="mt-3" style="font-size: 20px; font-weight: 600">
                Get Hashing Power !
              </p>
              <a>
                Just deposit bitcoin into your wallet and manage your hashpower
              </a>
            </center>
          </div>
          <div class="col-md-4 mt-3">
            <center>
              <a
                style="
                  border-radius: 50px;
                  border-color: #9e9b9b;
                  border-style: solid;
                  border-width: 10px;
                  width: 100px;
                  height: 100px;
                "
                class="d-flex align-items-center justify-content-center"
              >
                <div class="ml-n1">
                  <i class="fa fa-bitcoin" style="font-size: 32px"></i>
                </div>
              </a>
              <p class="mt-3" style="font-size: 20px; font-weight: 600">
                Mining your BTC
              </p>
              <a>
                You will get your BTC everyday. You can transfer this BTC to
                your Bitcoin wallet.
              </a>
            </center>
          </div>
          <div class="col-md-4 mt-3">
            <center>
              <a
                style="
                  border-radius: 50px;
                  border-color: #9e9b9b;
                  border-style: solid;
                  border-width: 10px;
                  width: 100px;
                  height: 100px;
                "
                class="d-flex align-items-center justify-content-center"
              >
                <div class="ml-n2">
                  <i class="fa fa-upload" style="font-size: 32px"></i>
                </div>
              </a>
              <p class="mt-3" style="font-size: 20px; font-weight: 600">
                Increase your power hash
              </p>
              <a>
                If you want to increase you powerhash you can purchase more
                power or use referral program.
              </a>
            </center>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 mb-5" style="background-color: #1c2b36; color: white">
      <div class="pt-3 pb-3">
        <center>
          <h3 style="font-weight: 500">
            Create your user account right nows and get your present
          </h3>
          <p>
            To simplify the understanding of our service operation, Create a
            Free Account Today
          </p>
          <h4>
            We are growing rapidly and we have mining farm in thailand and
            Antpool
          </h4>
        </center>
      </div>
    </div>
    <div>
      <div class="row justify-content-center">
        <div class="col-md-10">
          <center>
            <h2 style="font-weight: 600">Our Featured</h2>
          </center>
          <div class="row justify-content-center">
            <div class="col-md-4 mt-3">
              <center>
                <div
                  style="
                    background-color: #1c2b36;
                    border-style: solid;
                    border-width: 10px;
                    width: 95%;
                    color: white;
                  "
                  class="justify-content-center"
                >
                  <h3 style="font-weight: 600">Daily Rewards</h3>
                  <br />
                  <i class="fa fa-dollar" style="font-size: 42px"></i>
                  <h4>Take rewards every day</h4>
                </div>
              </center>
            </div>
            <div class="col-md-4 mt-3">
              <center>
                <div
                  style="
                    background-color: #1c2b36;
                    border-style: solid;
                    border-width: 10px;
                    width: 95%;
                    color: white;
                  "
                  class="justify-content-center"
                >
                  <h3 style="font-weight: 600">Withdraw in 24 hrs</h3>
                  <br />
                  <i class="fa fa-hourglass" style="font-size: 42px"></i>
                  <h4>Operation on Blockchain</h4>
                </div>
              </center>
            </div>
            <div class="col-md-4 mt-3">
              <center>
                <div
                  style="
                    background-color: #1c2b36;
                    border-style: solid;
                    border-width: 10px;
                    width: 95%;
                    color: white;
                  "
                  class="justify-content-center"
                >
                  <h3 style="font-weight: 600">Transfer Powerhash</h3>
                  <br />
                  <i class="fa fa-compress" style="font-size: 42px"></i>
                  <h4>Transfer powerhash peer to peer</h4>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5" style="background-color: #1c2b36; color: white">
      <div class="pt-5 pb-5">
        <center>
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <a style="font-size: 36px; color: white"> 55,466+ </a>
                  <br />
                  <a> Members </a>
                </div>
                <div class="col-md-4 mt-2">
                  <a style="font-size: 36px; color: white"> 15.1+ </a>
                  <br />
                  <a> PowerHash (EH/s) by AntPool </a>
                </div>
                <div class="col-md-4 mt-2">
                  <a style="font-size: 36px; color: white"> 1,757.49682119+ </a>
                  <br />
                  <a> BTC Supply </a>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-9">
        <div class="m-2">
          <br />
          <center>
            <img src="static/img/s9.jpg" style="width: 97%" alt="avatar" />
          </center>
          <h2 style="font-weight: 600">Powered By AntPool</h2>
          <p style="font-size: 14px">
            Our Bussiness Partnernership To Number #1 World Leading Cloud
            Provider
          </p>
          <a>
            The Antminer S9 is not only the first 16nm miner for retail use but,
            using less than half the energy per gigahash of the S7, it is also
            the world's most power-efficient miner that is ready to order.
          </a>
          <img
            src="https://hashbx.com/themes/onevesting/img/placeholders/screenshots/partnership.png"
            style="width: 100%"
          />
        </div>
      </div>
    </div>
    <div class="mt-5" style="background-color: #1c2b36; color: white">
      <div class="pt-5 pb-5">
        <center>
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="row justify-content-end">
                <br />

                <div class="m-4">
                  <a href="/terms" target="_blink"> Terms & Conditions </a>
                  <br />
                  <i class="fa fa-users" style="color: white"></i>
                  Contact as Line: @hashbx
                </div>

                <br />
              </div>
            </div>
          </div>
        </center>
      </div>
    </div>
  </div>
</template>
<script>
import { FadeRenderTransition } from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      priceBTCUSDT: "",
    };
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    copyText(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  created() {
    Swal.fire({
      title: `Announce`,
      text: `To support the mining system in the near future
and better usability
The company has developed a new Upgrade Website for the whole system.
All members can Active Verify their identity.
to access the account
*Notification of registration to verify new system identity
Using the same E-mal at Line : @hashbx`,
      type: "success",
      confirmButtonClass: "btn btn-success",
      buttonsStyling: false,
    });
    setInterval(() => {
      axios
        .get(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
        .then((response) => {
          this.priceBTCUSDT = parseFloat(response.data.price).toFixed(2);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 15000);
    axios
      .get(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
      .then((response) => {
        this.priceBTCUSDT = parseFloat(response.data.price).toFixed(2);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style></style>
