<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <li>
            <router-link :to="{ path: '/auth' }">Version 2.0</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        
        <i class="fa fa-heart heart"></i> HashBx.com new version V2.0
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
