var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'ADD MONEY',
          icon: 'fa fa-money',
          path: '/adminaddmoney',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'KYC Verification',
          icon: 'fa fa-user',
          path: '/adminkyc',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Withdrawals List',
          icon: 'fa fa-key',
          path: '/adminwithdrawalslist',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Contract',
          icon: 'fa fa-signal',
          path: '/adminccontract',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'TRANSACTIONS',
          icon: 'fa fa-calendar',
          path: '/admintransactions',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'View User',
          icon: 'fa fa-user',
          path: '/adminviewuser',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'All Deposit',
          icon: 'fa fa-gift',
          path: '/admindepositall',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Fund Summary',
          icon: 'fa fa-bar-chart',
          path: '/adminfund',
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{staticClass:"m-1 mt-3"},[_c('div',{staticClass:"m-3"},[_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.funds),function(fun){return _c('div',{key:fun,staticClass:"col-md-4 m-2",staticStyle:{"background-color":"#dbe7f5"}},[(fun.currency != 'undefined')?_c('div',[_c('a',[_c('img',{staticStyle:{"max-width":"40px","margin-top":"10px"},attrs:{"src":require(("@/assets/tokens/" + (fun.currency) + ".png"))}}),_c('a',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(fun.currency)+" ")])]),_c('div',{staticClass:"m-1"},[_c('a',{staticStyle:{"font-size":"14px"}},[(fun.currency == 'BTC')?_c('a',[_vm._v(" Amount: "+_vm._s(parseFloat(fun.amount).toFixed(8))+" ")]):_c('a',[_vm._v(" Amount: "+_vm._s(parseFloat(fun.amount).toFixed(2)))]),_c('br'),(fun.currency == 'S9THs')?_c('a',[_vm._v(" In Mining: "+_vm._s(parseFloat(_vm.in_mining).toFixed(2))+" ")]):_c('a',[_vm._v(" In Mining: "+_vm._s(parseFloat(fun.staking).toFixed(2))+" ")]),_c('br'),(fun.currency == 'S9THs')?_c('a',[_vm._v(" In Farming: "+_vm._s(parseFloat(_vm.in_fram).toFixed(2))+" ")]):_c('a',[_vm._v(" In Farming: "+_vm._s(parseFloat(fun.staking).toFixed(2))+" ")]),_c('br'),(fun.currency == 'S9THs')?_c('a',[_vm._v("Balance: "),_c('a',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(( parseFloat(fun.balance) + parseFloat(_vm.in_fram) + parseFloat(_vm.in_mining) ).toFixed(2)))])]):(fun.currency == 'BTC')?_c('a',[_vm._v(" Balance: "),_c('a',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(parseFloat(fun.balance).toFixed(8)))])]):_c('a',[_vm._v(" Balance: "),_c('a',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(parseFloat(fun.balance).toFixed(2)))])])])])]):_vm._e()])}),0)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }