<template>
  <nav class="navbar navbar-expand-lg" style="background-color: #1c2b36">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button
          class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block"
          @click="minimizeSidebar"
        >
          <i
            :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"
          ></i>
        </button>
      </div>
      <a class="navbar-brand">{{ title }}</a>
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-expanded="false"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end">
        <ul class="nav navbar-nav mr-auto">
          <a
            style="font-size: 14px; font-weight: 600; color: white"
            class="ml-4"
            >MyPower ( S9 ):
            <a style="font-size: 14px"
              >{{
                parseFloat(
                  parseFloat(getBalance.S9THs.available) +
                    parseFloat(amounMiningS9)
                ).toFixed(2)
              }}
              TH/s</a
            ></a
          >

          <a
            class="ml-4"
            style="font-size: 14px; font-weight: 600; color: white"
            >MyHashBX:
            <a style="font-size: 14px"
              >{{
                (
                  parseFloat(getBalance.HBX.available) +
                  parseFloat(amounMiningHBX)
                ).toFixed(2)
              }}
              HBX</a
            ></a
          >
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

import user from "@/api/user";
import { API_URL } from "../../../config";
import investbox from "@/api/investbox";

export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  props: {
    title: String,
  },
  data() {
    return {
      activeNotifications: false,
      amounMiningS9: 0,
      amounMiningHBX: 0,
    };
  },
  computed: mapGetters(["getUser", "getBalance"]),

  methods: {
    ...mapActions(["fetchBalance", "setUser"]),

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    fetchUser() {
      axios
        .get(`${API_URL}/user/detail/${this.getUser._id}`)
        .then((response) => {
          this.setUser(response.data.users);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getdata() {
      let inMiningS9 = 0;
      let amounMiningHBX = 0;

      investbox
        .contract()
        .then((res) => {
          res.data.map((con) => {
            if (con.status != "COMPLETED" && con.status != "COMPLETE") {
              if (con.currencyIN == "S9THs") {
                inMiningS9 = inMiningS9 + parseFloat(con.amount);
              } else if (con.currencyIN == "HBX") {
                amounMiningHBX = amounMiningHBX + parseFloat(con.amount);
              }
            }
          });

          this.amounMiningS9 = inMiningS9;
          this.amounMiningHBX = amounMiningHBX;
        })
        .catch((error) => {});
    },
  },
  created() {
    this.fetchUser();

    this.getdata();

    this.fetchBalance();
  },
};
</script>
<style></style>
