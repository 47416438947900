import api from "@/api/index";

export default {
  //PUBLIC
  list(credentials) {
    return api().get("invest/list", credentials);
  },
  //MEMBER
  submit(credentials) {
    return api().post("contract/submit", credentials);
  },
  contract(credentials) {
    return api().get("contract", credentials);
  },
  //ADMIN
  delete(credentials) {
    return api().post("invest/delete", credentials);
  },
  create(credentials) {
    return api().post("invest/create", credentials);
  },
  update(credentials) {
    return api().post("invest/update", credentials);
  },
  listAll(credentials) {
    return api().get("contract/listAll", credentials);
  },
};
