<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="MINING"></top-navbar>

      <div class="mt-n5" style="background-color: #486382; color: white">
        <div class="p-4">
          <h2 style="font-weight: 600">MINING</h2>
          <p>Mining Power Hash to BTC.</p>
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="mr-4 mt-2">
          <a href="/myearn" style="color: #14b3f9; font-weight: 600">My Pool</a>
        </div>
      </div>

      <div class="m-3 mt-3" v-if="listInvest.length > 0">
        <div v-for="invest in listInvest" :key="invest">
          <div
            v-if="invest.type == 'MINING' && invest.avail >= invest.min_invest"
            class="row justify-content-center"
          >
            <div class="col-md-8">
              <card class="stacked-form">
                <form method="#" action="#" @submit.prevent>
                  <center>
                    <div style="">
                      <img
                        :src="
                          require(`@/assets/tokens/${invest.currencyOUT}.png`)
                        "
                        style="
                          width: 10%;
                          border-radius: 50%;
                          border: 5px solid #14b3f9;
                        "
                      />
                      <br />
                      <a style="font-size: 26px; font-weight: 600"
                        >{{ invest.type }} {{ invest.currencyIN }}</a
                      >
                    </div>
                  </center>

                  <br />
                  <br />
                  <p style="font-weight: 500">Conditions:</p>
                  <div class="ml-3" style="font-size: 14px">
                    <li>
                      Minimum Mining
                      <a style="color: #14b3f9; font-weight: 500">
                        {{ invest.min_invest }}
                      </a>
                      {{ invest.currencyIN }}
                    </li>
                    <li>
                      Power Hash Return after
                      <a style="color: #14b3f9; font-weight: 500"
                        >{{ invest.period }}
                      </a>
                      Days
                    </li>
                    <li>
                      Daily Rewards
                      <a style="color: #14b3f9; font-weight: 500">
                        {{
                          parseFloat(
                            900 / parseFloat(invest.powerHash)
                          ).toFixed(8)
                        }}
                      </a>
                      BTC / THs
                    </li>
                    <li>
                      Mining Supply
                      <a style="color: #14b3f9; font-weight: 500">
                        {{ invest.cost_ratio }}
                      </a>
                      CRD per Day
                    </li>
                    <li>
                      Global Hashrate
                      <a style="color: #14b3f9; font-weight: 500">
                        {{ invest.powerHash }}
                      </a>
                      TH/s
                    </li>
                  </div>
                  <div>
                    <br />
                    <a style="font-size: 14px; font-weight: 500"
                      >Amount {{ invest.currencyIN }} ( POWER HASH )</a
                    >
                    <br />
                    <br />
                    <fg-input
                      v-model="amountPowerCoin"
                      type="number"
                      placeholder="Enter S9THs Amount"
                    >
                    </fg-input>
                    <div v-if="invest.currencyIN == 'S9THs'">
                      <div class="row justify-content-end">
                        <div class="mr-3">
                          <p style="font-size: 14px">
                            <a style="color: #14b3f9">
                              {{
                                parseFloat(getBalance.S9THs.available).toFixed(
                                  2
                                )
                              }}
                            </a>
                            {{ invest.currencyIN }}
                            ( POWER HASH )
                          </p>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="invest.currencyIN == 'S19THs'">
                      <div class="row justify-content-end">
                        <div class="mr-3">
                          <p style="font-size: 14px">
                            <a style="color: #14b3f9">
                              {{
                                parseFloat(getBalance.S19THs.available).toFixed(
                                  2
                                )
                              }}
                            </a>
                            {{ invest.currencyIN }}
                            ( POWER HASH )
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-end">
                      <div class="mr-3">
                        <p style="font-size: 14px">
                          <a style="color: #14b3f9">
                            {{
                              parseFloat(getBalance.CRD.available).toFixed(2)
                            }}
                          </a>
                          ( CRD )
                        </p>
                      </div>
                    </div>

                    <p style="font-weight: 500">Approximately:</p>
                    <div class="ml-3" style="font-size: 14px">
                      <li>
                        Mining
                        <a
                          style="
                            color: #14b3f9;
                            font-weight: 500;
                            font-size: 16px;
                          "
                          >{{ amountPowerCoin }}</a
                        >
                        {{ invest.currencyIN }}
                      </li>
                      <li>
                        Mining Supply
                        <a style="color: #14b3f9; font-weight: 500">
                          {{
                            (
                              amountPowerCoin *
                              invest.cost_ratio *
                              invest.period
                            ).toFixed(2)
                          }}
                        </a>
                        CRD per 15 Days
                      </li>
                      <li>
                        Daily Rewards
                        <a style="color: #14b3f9; font-weight: 500">
                          {{
                            (
                              parseFloat(
                                900 / parseFloat(invest.powerHash)
                              ).toFixed(8) * amountPowerCoin
                            ).toFixed(8)
                          }}
                        </a>
                        BTC
                      </li>

                      <li>
                        Total Rewards 15 Days
                        <a style="color: #14b3f9; font-weight: 500">
                          {{
                            (
                              parseFloat(
                                900 / parseFloat(invest.powerHash)
                              ).toFixed(8) *
                              amountPowerCoin *
                              15
                            ).toFixed(8)
                          }}
                        </a>
                        BTC
                      </li>
                    </div>

                    <br />

                    <center>
                      <div>
                        <l-button
                          type="submit"
                          class="btn btn-fill btn-info"
                          size="lg"
                          @click="submitInvest(invest)"
                        >
                          MINING POOL
                        </l-button>
                      </div>
                    </center>
                  </div>
                </form>
              </card>
            </div>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import axios from "axios";

import Swal from "sweetalert2";

import { mapGetters, mapActions } from "vuex";

import investbox from "@/api/investbox";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      pricePer: "300.000",
      listInvest: [],
      amountPowerCoin: 0,
    };
  },
  computed: mapGetters(["getUser", "getBalance"]),
  methods: {
    ...mapActions(["Logout", "fetchBalance"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },
    async investList() {
      await investbox
        .list()
        .then((res) => {
          this.listInvest = res.data;
        })
        .catch((error) => {
          alert("err");
        });
    },
    submitInvest(invest) {
      if (this.amountPowerCoin <= 0) {
        this.alertText(
          `Amount minimum 1 Power hash`,
          `Plase amount 1 Power hash`
        );
      } else {
        if (
          this.amountPowerCoin > parseFloat(this.getBalance.S9THs.available)
        ) {
          this.alertText(`You don't have enough Balance`);
        } else {
          if (
            (this.amountPowerCoin * invest.cost_ratio * invest.period).toFixed(
              4
            ) > parseFloat(this.getBalance.CRD.available)
          ) {
            this.alertText(
              `You don't have enough Balance`,
              `You don't have enough Balance`
            );
          } else {
            Swal.fire({
              title: `Mining ${this.amountPowerCoin} ${
                invest.currencyIN
              } pay ${(
                this.amountPowerCoin *
                invest.cost_ratio *
                invest.period
              ).toFixed(2)} CRD to earn ${(
                parseFloat(900 / parseFloat(invest.powerHash)).toFixed(8) *
                this.amountPowerCoin
              ).toFixed(
                8
              )} BTC daily rewards Capital return after 15 days, Total ${(
                (
                  parseFloat(900 / parseFloat(invest.powerHash)).toFixed(8) *
                  this.amountPowerCoin
                ).toFixed(8) * 15
              ).toFixed(8)} BTC`,
              text: `Usage of particular area carriers risks. We do not guarantee payback , income or profit. Investment carries risks such as price risk , platform and many others. Assets invested increase or decrease or even turned zero . Investors should study all information before any investment and accept risks that may occur. Moreover the company will not be responsible for any kind of loses directly or indirectly.
              

Before using services calculation of mining have to be done to check whether does it worth the cost of investment as there are factors affecting like electricity and difficulties that increase on daily bases that investor need to be concerned and study to accept risks along with terms  and conditions of the system then you can press the button to start using services

Our company reserved right and use discretely decision by oneself to edit or change terms and conditions along with details on this website or even cancellation of the agreement and access to some part of the details. The company reserved right to make any changes without notification or reasons.`,
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Accepted",
              cancelButtonText: "Cancel",
              confirmButtonClass: "btn btn-success btn-fill",
              cancelButtonClass: "btn btn-danger btn-fill",
              buttonsStyling: false,
            }).then((res) => {
              if (res.isConfirmed) {
                this.submit(
                  invest._id,
                  this.amountPowerCoin * invest.cost_ratio
                );
              }
            });
          }
        }
      }
    },
    alertText(title, subtext) {
      Swal.fire({
        title: title,
        text: subtext,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-info btn-fill",
      });
    },
    async submit(id, amount) {
      await investbox
        .submit({
          invest_id: id,
          amount: this.amountPowerCoin,
        })
        .then((res) => {
          this.fetchBalance();

          setTimeout(() => {
            Swal.fire({
              title: "Success",
              text: "Create order mining success",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-success btn-fill",
            });
          }, 100);
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.error,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning btn-fill",
          });
        });
    },
  },
  created() {
    this.investList();
  },
};
</script>
