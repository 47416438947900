var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Summary',
          icon: 'fa fa-globe',
          path: '/overview',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'MINING',
          icon: 'fa fa-line-chart',
          path: '/managepool',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'FARMING',
          icon: 'fa fa-ship',
          path: '/farm',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'TRANSACTIONS',
          icon: 'fa fa-calendar',
          path: '/transection',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'TRANSFER',
          icon: 'fa fa-exchange',
          path: '/transfer',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'MY FUNDS',
          icon: 'fa fa-money',
          path: '/fund',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'AFFILIATE',
          icon: 'fa fa-sitemap',
          path: '/affiliate',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'MY PROFILE',
          icon: 'fa fa-address-card',
          path: '/profile',
        }}}),_c('sidebar-item',{attrs:{"link":{ name: 'SETTINGS', icon: 'fa fa-cog' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Change Password', path: '/changepass' }}})],1),_c('br'),_c('center',[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.signout()}}},[_c('a',{staticStyle:{"font-size":"14px","font-weight":"600"}},[_vm._v(" LOGOUT ")])])])],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar',{attrs:{"title":"Transaction"}}),_vm._m(0),_c('div',{staticClass:"m-1 mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title",staticStyle:{"color":"#14b3f9","font-weight":"500"}},[_vm._v(" TRANSACTIONS ")])]),_c('div',{staticClass:"table-responsive table-full-width"},[_c('el-table',{staticClass:"table-striped",attrs:{"data":_vm.table_rows}},[_c('el-table-column',{attrs:{"property":"createdAt"}}),_c('el-table-column',{attrs:{"property":"remark"}}),_c('el-table-column',{attrs:{"property":"currency"}}),_c('el-table-column',{attrs:{"property":"amount"}}),_c('el-table-column',{attrs:{"property":"type"}})],1)],1)])],1)])]),_c('content-footer')],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"mr-4 mt-2"},[_c('a',{staticStyle:{"color":"#14b3f9","font-weight":"600"},attrs:{"href":"/myearn"}},[_vm._v("My Pool")])])])}]

export { render, staticRenderFns }