import Vuex from "vuex";
import Vue from "vue";

import auth from "./modules/auth";
import exchange from "./modules/exchange";
import info from "./modules/info";
import invest from "./modules/invest";
import user from "./modules/user";

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    exchange,
    info,
    invest,
    user
  }
});
