<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="Deposit HBX"></top-navbar>

      <div class="m-3 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-11">
            <div class="row">
              <div class="col-md-6">
                <card class="stacked-form" title="">
                  <form method="#" action="#" @submit.prevent>
                    <center>
                      <a style="font-size: 18px; font-weight: 600">
                        Deposit History
                      </a>
                      <br />
                    </center>

                    <br />

                    <div class="m-1">
                      <div v-for="(item, index) in table_deposit" :key="index">
                        <div class="row">
                          <div class="col-8">
                            <img
                              style="max-width: 40px"
                              :src="
                                require(`@/assets/tokens/${item.currency}.png`)
                              "
                            />
                            <a
                              v-if="item.status == 'COMPLETED'"
                              class="p-1"
                              style="
                                background-color: green;
                                color: white;
                                font-size: 14px;
                                font-weight: 600;
                                border-radius: 5px;
                              "
                            >
                              {{ item.status }}
                            </a>
                            <a
                              v-else-if="item.status == 'INCOMMING'"
                              class="p-1"
                              style="
                                background-color: #e5bc03;
                                color: white;
                                font-size: 14px;
                                font-weight: 600;
                                border-radius: 5px;
                              "
                            >
                              {{ item.status }}
                            </a>
                            <a
                              v-else-if="item.status == 'REJECTED'"
                              class="p-1"
                              style="
                                background-color: red;
                                color: white;
                                font-size: 14px;
                                font-weight: 600;
                                border-radius: 5px;
                              "
                            >
                              {{ item.status }}
                            </a>
                            <br />
                            <a style="font-size: 14px">
                              {{ item.createdAt }}
                            </a>
                          </div>
                          <div class="col-4">
                            {{ item.amount }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                  </form>
                </card>
              </div>
              <div class="col-md-6">
                <card class="stacked-form" title="">
                  <form method="#" action="#" @submit.prevent>
                    <center>
                      <a style="font-size: 18px; font-weight: 600">
                        Withdrawal History
                      </a>
                      <br />
                    </center>

                    <br />

                    <div class="m-1">
                      <div
                        v-for="(item, index) in table_withdrawal"
                        :key="index"
                      >
                        <div class="row" v-on:click="expand(item.hash)">
                          <div class="col-8">
                            <img
                              style="max-width: 40px"
                              :src="
                                require(`@/assets/tokens/${item.currency}.png`)
                              "
                            />
                            <a
                              v-if="item.status == 'COMPLETED'"
                              class="p-1"
                              style="
                                background-color: green;
                                color: white;
                                font-size: 14px;
                                font-weight: 600;
                                border-radius: 5px;
                              "
                            >
                              {{ item.status }}
                            </a>
                            <a
                              v-else-if="item.status == 'PROCESSING'"
                              class="p-1"
                              style="
                                background-color: #e5bc03;
                                color: white;
                                font-size: 14px;
                                font-weight: 600;
                                border-radius: 5px;
                              "
                            >
                              {{ item.status }}
                            </a>
                            <a
                              v-else-if="item.status == 'REJECTED'"
                              class="p-1"
                              style="
                                background-color: red;
                                color: white;
                                font-size: 14px;
                                font-weight: 600;
                                border-radius: 5px;
                              "
                            >
                              {{ item.status }}
                            </a>
                            <br />
                            <a style="font-size: 14px">
                              {{ item.createdAt }}
                            </a>
                          </div>
                          <div class="col-4">
                            {{ item.amount }}
                          </div>
                        </div>
                        <div v-if="expanded == item.hash">
                          <div class="m-4">
                            <div style="font-size: 14px">
                              <div class="row">
                                <div class="col-4">STATUS</div>
                                <div class="col-8">
                                  {{ item.status }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-4">To Address</div>
                                <div class="col-8">
                                  {{ item.address }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-4">Amount</div>
                                <div class="col-8">
                                  {{ item.amount }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-4">TX_ID</div>
                                <div class="col-8">
                                  {{ item.tx_id }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-4">Hash</div>
                                <div class="col-8">
                                  {{ item.hash }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>

                    <br />
                  </form>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
import user from "@/api/user";
import { API_URL } from "../../../config";
import investbox from "@/api/investbox";

import VueQrcode from "vue-qrcode";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueQrcode,
  },
  data() {
    return {
      address: "",
      msg: "Copy",
      expanded: "",
      table_withdrawal: [],
      table_deposit: [],
    };
  },
  computed: mapGetters(["getUser", "getBalance", "getWallet"]),
  methods: {
    ...mapActions(["Logout", "fetchWallet"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },
    copyAddress() {
      let textToCopy = document.querySelector("#text-address");
      textToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      textToCopy.select();

      try {
        document.execCommand("copy");
        this.msg = "Copied";
      } catch (err) {}

      /* unselect the range */
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    getWithdrawalHistory() {
      user
        .withdrawhistory()
        .then((res) => {
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.table_withdrawal.push(e);
          });
        })
        .catch((error) => {});
    },
    getDepositHistory() {
      user
        .deposithistory()
        .then((response) => {
          this.ready = true;
          response.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.table_deposit.push(e);
          });
        })
        .catch((error) => {});
    },
    expand(id) {
      if (this.expanded == id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },
  },
  created() {
    this.fetchWallet()
      .then((w) => {})
      .catch((e) => {
        this.Logout();
        this.$router.push("/");
      });
    this.getWithdrawalHistory();
    this.getDepositHistory();
  },
};
</script>
