<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="My Power"></top-navbar>

      <div class="m-3 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <card
              class="stacked-form"
              style="
                border: 0.5px solid #14b3f9;
                vertical-align: middle;
                padding: 1px;
              "
            >
              <div class="row">
                <div class="col-12 mt-2">
                  <center>
                    <a
                      style="font-size: 14px; font-weight: 600; color: #14b3f9"
                    >
                      Equity Value(BTC)
                    </a>
                    <br />
                    <a style="font-size: 24px"
                      >{{ getBalance.BTC.balance }}
                      <a style="font-weight: 600">BTC</a></a
                    >
                    <br />
                    <a
                      >≈ $
                      {{
                        (this.getBalance.BTC.balance * priceBTCUSDT).toFixed(
                          2
                        ) + this.getBalance.CRD.balance
                      }}
                      <a style="font-weight: 600">USD</a></a
                    >
                  </center>
                </div>
              </div>
            </card>

            <div>
              <div v-for="b in getBalance" v-bind:key="b.id">
                <div class="coin">
                  <a @click="expand(b.coin)">
                    <div class="row pt-2 pb-1">
                      <div class="col-6">
                        <div class="ml-2">
                          <img
                            style="max-width: 40px"
                            :src="require(`@/assets/tokens/${b.coin}.png`)"
                          />
                          <a
                            class="ml-1"
                            style="font-size: 14px; font-weight: 600"
                            >{{ b.coin }}</a
                          >
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="row justify-content-end mr-3">
                          <div>
                            <a style="font-size: 14px; font-weight: 600">
                              {{ getValue(b.coin, b.available) }}
                            </a>
                          </div>
                        </div>
                        <div class="row justify-content-end mr-3">
                          <a
                            v-if="b.coin == 'BTC' || b.coin == 'CRD'"
                            style="font-size: 12px"
                          >
                            ≈ $ {{ getValuePrice(b.coin, b.available) }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </a>

                  <div
                    v-if="expanded == b.coin"
                    class="row mt-3 mb-3"
                    style="font-size: 12px"
                  >
                    <div class="col-4">
                      <div class="row justify-content-end">
                        <div
                          v-if="
                            b.coin == 'BTC' ||
                              b.coin == 'CRD' ||
                              b.coin == 'S9THs'
                          "
                        >
                          <a @click="showConvertBTC()" href="#">
                            <i
                              class="fa fa-hand-o-right"
                              style="font-size: 20px; color: #14b3f9"
                            ></i>
                            <a class="ml-2"> Convert </a>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row justify-content-center">
                        <div class="col-6">
                          <div v-if="b.coin == 'HBXBNB'">
                            <a
                              target="_blink"
                              href="https://liff.line.me/1645278921-kWRPP32q?accountId=hashbx&openerPlatform=webview&openerKey=webview%3AunifiedSearch"
                            >
                              <i
                                class="fa fa-cloud-download"
                                style="font-size: 20px; color: #14b3f9"
                              ></i>
                              <a class="ml-2"> Deposit </a>
                            </a>
                          </div>
                          <!-- <div v-if="b.coin == 'HBX'">
                            <a href="/deposit">
                              <i
                                class="fa fa-cloud-download"
                                style="font-size: 20px; color: #14b3f9"
                              ></i>
                              <a class="ml-2"> Deposit </a>
                            </a>
                          </div> -->
                        </div>
                        <div class="col-6">
                          <div
                            v-if="
                              b.coin == 'BTC' ||
                                b.coin == 'DOGE' ||
                                b.coin == 'HBX' ||
                                b.coin == 'HBXBNB'
                            "
                          >
                            <a href="/Withdrawal">
                              <i
                                class="fa fa-cloud-upload"
                                style="font-size: 20px; color: #14b3f9"
                              ></i>
                              <a class="ml-2"> Withdrawal </a>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-4 ml-n2">
                      <div
                        v-if="
                          b.coin != 'POOLBONUS' &&
                            b.coin != 'REBATE' &&
                            b.coin != 'DOGE' &&
                            b.coin != 'BTC'
                        "
                      >
                        <a href="/transfer">
                          <i
                            class="fa fa-exchange"
                            style="font-size: 20px; color: #14b3f9"
                          ></i>
                          <a class="ml-2"> Transfer </a>
                        </a>
                      </div>
                      <div v-if="b.coin == 'POOLBONUS' || b.coin == 'REBATE'">
                        <a @click="showConvertBTC()" href="#">
                          <i
                            class="fa fa-heartbeat"
                            style="font-size: 20px; color: #14b3f9"
                          ></i>
                          <a class="ml-2"> Take Bonus </a>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      (expanded == 'BTC' ||
                        expanded == 'CRD' ||
                        expanded == 'S9THs') &&
                        expanded == b.coin &&
                        showConvert
                    "
                  >
                    <br />

                    <center>
                      <a v-if="b.coin == 'BTC'">
                        <fg-input
                          :label="
                            'Amount Convert ' +
                              '( Minimum ' +
                              ' 0.0001 ' +
                              expanded +
                              ' )'
                          "
                          v-model="amount_coin"
                          :placeholder="'Enter amount ' + b.coin"
                          type="Number"
                        >
                        </fg-input>
                      </a>
                      <a v-else-if="b.coin == 'S9THs'">
                        <fg-input
                          :label="
                            'Amount Convert ' +
                              '( Minimum ' +
                              ' 0.01 ' +
                              expanded +
                              ' )  | 1 ' +
                              expanded +
                              ' = 10,000 HBX'
                          "
                          v-model="amount_coin"
                          :placeholder="'Enter amount ' + b.coin"
                          type="Number"
                        >
                        </fg-input>
                      </a>
                      <a v-else>
                        <fg-input
                          :label="
                            'Amount Convert ' +
                              '( Minimum ' +
                              ' 1 ' +
                              expanded +
                              ' ) | 1 CRD = 100 HBX'
                          "
                          v-model="amount_coin"
                          :placeholder="'Enter amount ' + b.coin"
                          type="Number"
                        >
                        </fg-input>
                      </a>

                      <a v-if="expanded == 'BTC'">
                        <button
                          class="btn btn-fill btn-warning"
                          @click="convertBTC()"
                        >
                          <a style="font-size: 22px; font-weight: 600">
                            Convert BTC / CRD
                          </a>
                        </button>
                      </a>
                      <a v-else-if="expanded == 'CRD'">
                        <button class="btn btn-fill" @click="convertCRD()">
                          <a style="font-size: 22px; font-weight: 600">
                            Convert CRD / HBX
                          </a>
                        </button>
                      </a>
                      <a v-else-if="expanded == 'S9THs'">
                        <button
                          class="btn btn-success"
                          @click="convert_S9(expanded)"
                        >
                          <a style="font-size: 22px; font-weight: 600">
                            Convert S9THs / HBX
                          </a>
                        </button>
                      </a>
                    </center>
                    <br />
                  </div>
                  <div
                    v-if="
                      (expanded == 'REBATE' || expanded == 'POOLBONUS') &&
                        expanded == b.coin &&
                        showConvert
                    "
                  >
                    <br />
                    <center>
                      <fg-input
                        label="Take Bonus, Amount Pool Bonus and Rebate ( Minimum 1 CRD )"
                        v-model="amount_coin"
                        placeholder="Enter amount Take Bonus ( Minimum 1 CRD )"
                        type="Number"
                      >
                      </fg-input>

                      <button
                        class="btn btn-fill btn-warning"
                        @click="takeBonus()"
                      >
                        <a style="font-size: 22px; font-weight: 600">
                          TakeBonus
                        </a>
                      </button>
                    </center>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
import user from "@/api/user";
import { API_URL } from "../../../config";
import investbox from "@/api/investbox";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      pricePer: "300.000",
      address: "",
      amount: "",
      power_hashGH: 0,
      power_supply: 0,
      valueBTC: {},
      priceBTC: 0,
      amountReceive: "--",
      amountAvailable: 0,
      table_rows: [],
      amounMiningS9: 0,
      amounMiningS19: 0,
      priceBTCUSDT: 0,
      priceDOGEUSDT: 0,
      priceHBXUSDT: 0,
      amount_coin: 0,
      expanded: null,
      showConvert: false,
      showTakeBonus: false,
    };
  },
  computed: mapGetters(["getUser", "getBalance"]),
  methods: {
    ...mapActions(["Logout", "setUser", "fetchBalance", "fetchWallet"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },
    fetchUser() {
      axios
        .get(`${API_URL}/user/detail/${this.getUser._id}`)
        .then((response) => {
          this.setUser(response.data.users);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getValue(coin, balance) {
      if (coin == "S9THs") {
        if (this.inMiningS9 != 0) {
          return parseFloat(
            parseFloat(this.amounMiningS9) + parseFloat(balance)
          ).toFixed(8);
        } else {
          return balance;
        }
      } else if (coin == "S19THs") {
        if (this.inMiningS19 != 0) {
          return parseFloat(
            parseFloat(this.amounMiningS19) + parseFloat(balance)
          ).toFixed(8);
        } else {
          return balance;
        }
      } else {
        return balance;
      }
    },
    getValuePrice(coin, value) {
      if (coin == "S19THs" || coin == "S9THs") {
        return "0.00";
      } else if (coin == "BTC") {
        return parseFloat(value * this.priceBTCUSDT).toFixed(2);
      } else if (coin == "DOGE") {
        return parseFloat(value * this.priceDOGEUSDT).toFixed(2);
      } else if (coin == "HBX") {
        return parseFloat(value * this.priceHBXUSDT).toFixed(2);
      } else {
        return parseFloat(value).toFixed(2);
      }
    },
    getInves() {
      let inMiningS9 = 0;
      let inMiningS19 = 0;
      investbox
        .contract()
        .then((res) => {
          res.data.map((con) => {
            if (con.status != "COMPLETED" && con.status != "COMPLETE") {
              if (con.currencyIN == "S9THs") {
                inMiningS9 = inMiningS9 + parseFloat(con.amount);
              } else if (con.currencyIN == "S19THs") {
                inMiningS19 = inMiningS19 + parseFloat(con.amount);
              }
            }
          });

          this.amounMiningS9 = inMiningS9;
          this.amounMiningS19 = inMiningS19;
        })
        .catch((error) => {});
    },
    getPriceBTC() {
      axios
        .get(`https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`)
        .then((response) => {
          this.priceBTCUSDT = parseFloat(response.data.price).toFixed(2);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPriceDOGE() {
      axios
        .get(`https://api.binance.com/api/v3/ticker/price?symbol=DOGEUSDT`)
        .then((response) => {
          this.priceDOGEUSDT = parseFloat(response.data.price).toFixed(2);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // getPriceHBX() {
    //   axios
    //     .get(`https://api.365.stream/coins`)
    //     .then((response) => {
    //       this.priceHBXUSDT = parseFloat(
    //         response.data["HBX"].last_price
    //       ).toFixed(7);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    expand(coin) {
      if (this.expanded == coin) {
        this.expanded = null;
        return;
      }
      this.expanded = coin;
      this.amount_coin = 0;
      this.showConvert = false;
    },
    showConvertBTC() {
      if (this.showConvert) {
        this.showConvert = false;
      } else {
        this.showConvert = true;
      }
    },
    async convertBTC() {
      setTimeout(() => {
        if (
          this.amount_coin <= 0 ||
          this.amount_coin > this.getBalance.BTC.available
        ) {
          Swal.fire({
            title: "You don't have enough Balance",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-info btn-fill",
          });
        } else {
          Swal.fire({
            title: `The is approximate estimation, convert ${
              this.amount_coin
            } BTC to ${(this.priceBTCUSDT * this.amount_coin).toFixed(2)} CRD`,
            text:
              "As this transaction can not be canceled or transform back  CRD is the unit used in the system only Can not be transform to BTC or any other Are you ready to accept risks , terms and conditions. If yes,please click on Yes,convert  if not click Cancel",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Convert",
            cancelButtonText: "Cancel",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          }).then((res) => {
            if (res.isConfirmed == true) {
              this.convertCoin("BTC");
            }
          });
        }
      }, 500);
    },
    async convertCRD(coin) {
      setTimeout(() => {
        if (
          this.amount_coin <= 0 ||
          this.amount_coin > this.getBalance.CRD.available
        ) {
          Swal.fire({
            title: "You don't have enough Balance",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-info btn-fill",
          });
        } else {
          Swal.fire({
            title: `The is approximate estimation, convert ${
              this.amount_coin
            } CRD  to ${(this.amount_coin / this.priceHBXUSDT).toFixed(
              2
            )}  HBX`,
            text:
              "As this transaction can not be canceled or transform back  HBX is the unit used in the system only Can not be transform to HBX or any other Are you ready to accept risks , terms and conditions. If yes,please click on Yes,convert  if not click Cancel",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Convert",
            cancelButtonText: "Cancel",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          }).then((res) => {
            if (res.isConfirmed == true) {
              this.convertCoin("CRD");
            }
          });
        }
      }, 500);
    },
    async convert_S9(coin) {
      setTimeout(() => {
        if (this.amount_coin <= 0) {
          Swal.fire({
            title: "You don't have enough Balance",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-info btn-fill",
          });
        } else if (
          parseFloat(this.amount_coin) >
          parseFloat(this.getBalance[coin].available)
        ) {
          Swal.fire({
            title: "You don't have enough Balance",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-info btn-fill",
          });
        } else {
          Swal.fire({
            title: `The is approximate estimation, convert ${
              this.amount_coin
            } ${coin}  to ${(this.amount_coin * 10000).toFixed(2)}  HBX`,
            text:
              "As this transaction can not be canceled or transform back  HBX is the unit used in the system only Can not be transform to HBX or any other Are you ready to accept risks , terms and conditions. If yes,please click on Yes,convert  if not click Cancel",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, Convert",
            cancelButtonText: "Cancel",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false,
          }).then((res) => {
            if (res.isConfirmed == true) {
              this.convertCoin(coin);
            }
          });
        }
      }, 500);
    },
    async convertCoin(coin) {
      if (coin == "BTC") {
        await user
          .convertCoin({
            user_id: this.getUser._id,
            currency: coin,
            amount: this.amount_coin,
          })
          .then((data) => {
            this.fetchBalance();

            this.amount_coin = null;
            Swal.fire({
              title: "Success",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-success btn-fill",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: error.response.data.error,
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning btn-fill",
            });
          });
      } else if (coin == "CRD") {
        await user
          .convertCoinCRD({
            user_id: this.getUser._id,
            currency: coin,
            amount: this.amount_coin,
          })
          .then((data) => {
            this.fetchBalance();

            this.amount_coin = null;
            Swal.fire({
              title: "Success",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-success btn-fill",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: error.response.data.error,
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning btn-fill",
            });
          });
      } else if (coin == "S9THs") {
        await user
          .convertS9({
            user_id: this.getUser._id,
            currency: coin,
            amount: this.amount_coin,
          })
          .then((data) => {
            this.fetchBalance();

            this.amount_coin = null;
            Swal.fire({
              title: "Success",
              buttonsStyling: false,
              confirmButtonClass: "btn btn-success btn-fill",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: error.response.data.error,
              buttonsStyling: false,
              confirmButtonClass: "btn btn-warning btn-fill",
            });
          });
      }
    },
    async takeBonus() {
      if (
        parseFloat(this.amount_coin) >
          parseFloat(this.getBalance.POOLBONUS.available) ||
        parseFloat(this.amount_coin) >
          parseFloat(this.getBalance.REBATE.available) ||
        this.amount_coin < 0 ||
        this.amount_coin == 0
      ) {
        Swal.fire({
          title: "You don't have enough Balance",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-info btn-fill",
        });
      } else {
        Swal.fire({
          title: `Take Bonus ${this.amount_coin} CRD`,

          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Convert",
          cancelButtonText: "Cancel",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
        }).then((res) => {
          if (res.isConfirmed == true) {
            this.confirmTakeBonus();
          }
        });
      }
    },
    async confirmTakeBonus() {
      await user
        .takeBonus({
          user_id: this.getUser._id,
          amount: this.amount_coin,
        })
        .then((data) => {
          this.fetchBalance();

          this.amount_coin = null;
          Swal.fire({
            title: "Success",
            buttonsStyling: false,
            confirmButtonClass: "btn btn-success btn-fill",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.error,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning btn-fill",
          });
        });
    },

    async getPrice() {
      await user.setting().then((res) => {
        res.data.find((coin) => {
          if (coin.name == "HBXUSDT") {
            this.priceHBXUSDT = parseFloat(coin.value);
          }
        });
      });
    },
  },
  created() {
    this.fetchBalance();
    this.getInves();
    this.getPriceBTC();
    this.getPriceDOGE();
    // this.getPriceHBX();
    this.getPrice();
    this.fetchWallet()
      .then((w) => {})
      .catch((e) => {
        this.Logout();
        this.$router.push("/");
      });
  },
};
</script>
<style scoped>
.coin {
  border-bottom: 0.5px solid #14b3f9;
  vertical-align: middle;
  padding: 1px;
}
</style>
