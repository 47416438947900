<template>
  <auth-layout pageClass="login-page">
    <div v-if="getToken">{{ this.$router.push("/fund") }}</div>

    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-5 col-md-6 col-sm-8">
        <ValidationObserver>
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form>
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">
                    New Password Sent
                  </h3>
                </div>
                <center>
                  <div class="m-1">
                    <a style="font-size: 14px">
                      We've sent you an email detailing your new password.
                      Please follow the instructions in the email.
                    </a>
                    <br />
                    <a
                      style="font-weight: 600; font-size: 22px; color: #23CCEF"
                    >
                      Go to email and get new password
                    </a>
                  </div>
                </center>
                <div class="m-1">
                  <a style="font-size: 14px">
                    If you haven't received the email, please try the following
                  </a>
                  <br />

                  <a style="font-size: 14px">
                    <ul>
                      <li>
                        Make sure the email address you provided is correct.
                      </li>
                      <li>
                        Check your Spam or Junk mail folders.
                      </li>
                      <li>
                        Make sure your email is functioning normally.
                      </li>
                    </ul>
                  </a>
                </div>

                <div class="text-center">
                  <router-link to="/login" class="card-category">
                    <button
                      type="submit"
                      class="btn btn-fill btn-info btn-round btn-wd"
                    >
                      login
                    </button>
                  </router-link>

                  <br />
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

import { API_URL, RECPATCHA_KEY } from "../../../config";
import nativeToast from "native-toast";
import { mapGetters, mapActions } from "vuex";

import { load } from "recaptcha-v3";

import auth from "@/api/auth";

import Swal from "sweetalert2";

import axios from "axios";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
    factor: "",
    token: null,
    loading: false,
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      factor: "",
      sendEmail: false,
      token: this.$route.params.token,
    };
  },
  computed: mapGetters(["getToken", "getUser"]),

  methods: {
    ...mapActions([
      "setToken",
      "setUser",
      "setSecure",
      "fetchBalance",
      "setBalance",
    ]),

    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  created() {
    axios
      .get(`${API_URL}/resetpass/${this.token}`)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
