export default {
  type(t) {
    var e = {
      label: `label-default`
    };

    //BUY SELL
    if (t == "BUY") {
      e.label = `label-buy`;
    } else if (t == "SELL") {
      e.label = `label-sell`;
    }
    //TRANSACTION
    else if (t == "RECEIVE") {
      e.label = `label-success`;
    } else if (t == "INCOME") {
      e.label = `label-info`;
    } else if (t == "DEPOSIT") {
      e.label = `label-success`;
    } else if (t == "WITHDRAW") {
      e.label = `label-danger`;
    } else if (t == "SEND") {
      e.label = `label-danger`;
    } else if (t == "DISTRIBUTION") {
      e.label = `label-primary`;
    } else if (t == "COMMISSION") {
      e.label = `label-primary`;
    } else if (t == "INVEST") {
      e.label = `label-accent`;
    }
    //MY INVEST
    else if (t == "INVESTING") {
      e.label = `label-info`;
    }
    //WITHDRAWAL
    else if (t == "COMPLETED") {
      e.label = `label-success`;
    } else if (t == "REJECTED") {
      e.label = `label-danger`;
    } else if (t == "CANCELED") {
      e.label = `label-danger`;
    } else if (t == "WAIT_FOR_AUDIT") {
      e.label = `label-primary`;
    } else if (t == "PROCESSING") {
      e.label = `label-accent`;
    }
    //DEPOSIT
    else if (t == "PENDING") {
      e.label = `label-primary`;
    } else if (t == "INCOMMING") {
      e.label = `label-accent`;
    }

    return e;
  }
};
