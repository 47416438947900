<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="m-1 mt-3">
        <div class="m-3">
          <div>
            <a href="/admincreateminingpool">
              <i class="fa fa-edit" style="color: green; font-size: 20px"></i>
              Create Minig</a
            >
            <a style="margin-left: 15px" href="/admincreatestaking">
              <i class="fa fa-edit" style="color: green; font-size: 20px"></i>
              Create Staking</a
            >
            <a style="margin-left: 15px" href="/admincreatefarm">
              <i class="fa fa-edit" style="color: green; font-size: 20px"></i>
              Create Farming</a
            >
          </div>
        </div>

        <div class="col-md-12">
          <card>
            <div class="table-responsive table-full-width">
              <el-table class="table-striped" :data="contractList">
                <el-table-column
                  label="createdAt"
                  property="createdAt"
                ></el-table-column>
                <el-table-column label="type" property="type"></el-table-column>
                <el-table-column
                  label="total"
                  property="total"
                ></el-table-column>
                <el-table-column
                  label="avail"
                  property="avail"
                ></el-table-column>
                <el-table-column
                  label="days"
                  property="period"
                ></el-table-column>
                <el-table-column
                  label="currency OUT"
                  property="currencyOUT"
                ></el-table-column>
              </el-table>
            </div>
          </card>
        </div>
      </div>

      <div
        class="col-md-12 m-2"
        v-for="(item, index) in table_rows"
        :key="index"
      >
        <template>
          <div v-on:click="expand(item.hash)">
            <div class="row">
              <div class="col-4">
                <a style="font-size: 12px; font-weight: 600"
                  >{{ item.currencyIN }}
                  <i
                    class="fa fa-chevron-circle-right"
                    style="color: green; font-size: 20px"
                  ></i>
                  <a style="font-weight: 500">
                    {{ item.currencyOUT }} [ {{ item.day }}/{{ item.period }} ]
                    <br />
                    HASH_ID: {{ item.hashID }}
                  </a>
                </a>
              </div>

              <div class="col-2">
                <a style="font-size: 12px; font-weight: 500"
                  >{{ item.type }}
                </a>
              </div>
              <div class="col-3">
                <a style="font-size: 12px; font-weight: 500"
                  >user_id: {{ item.user_id }}
                </a>
              </div>
              <div class="col-3">
                <div v-if="item.status == 'INVESTING'">
                  <center>
                    <div style="background-color: #dab51b; border-radius: 10px">
                      <a
                        class="m-2"
                        style="font-size: 12px; color: white; font-weight: 500"
                      >
                        PROCESSING
                      </a>
                    </div>
                  </center>
                </div>
                <div v-else-if="item.status == 'COMPLETED'">
                  <center>
                    <div
                      style="
                        background-color: #1bb433;

                        border-radius: 25px;
                      "
                    >
                      <a
                        class="m-2"
                        style="font-size: 12px; color: white; font-weight: 500"
                        >{{ item.status }}
                      </a>
                    </div>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-if="expanded == item.hash">
          <div class="table-responsive table-full-width">
            <el-table class="table-striped" :data="history_mining">
              <el-table-column property="createdAt"></el-table-column>
              <el-table-column property="remark"></el-table-column>
              <el-table-column property="currency"></el-table-column>
              <el-table-column property="amount"></el-table-column>
              <el-table-column>
                <template slot-scope="props">
                  <div v-if="props.row.type == 'INCOME'">
                    <a>REWARDS</a>
                  </div>
                  <div v-else>
                    <a>{{ props.row.type }}</a>
                  </div>
                </template>
                <a></a>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <hr />
      </div>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import user from "@/api/user";

import { Table, TableColumn } from "element-ui";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";

import investbox from "@/api/investbox";

import Swal from "sweetalert2";

import moment from "moment";

import admin from "@/api/admin";

export default {
  components: {
    TopNavbar,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      contractList: [],
      table_rows: [],
      period: null,
      createdAt: null,
      countdown: null,
      ready: false,
      filter: "",
      history_mining: [],
      history_rows: [],
      page: 1,
      per_page: 19,
      expanded: null,
    };
  },
  computed: mapGetters(["getUser"]),

  methods: {
    getdata() {
      investbox
        .list()
        .then((res) => {
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.contractList.push(e);
          });
        })
        .catch((error) => {});
    },
    getListAll() {
      investbox
        .listAll()
        .then((res) => {
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            let countAmountHash = parseInt(e.hash.length);

            e.hashID =
              e.hash.slice(0, 6) +
              "........" +
              e.hash.slice(countAmountHash - 7, countAmountHash - 1);
            this.table_rows.push(e);
          });
        })
        .catch((error) => {});
    },
    fixed(a) {
      return func.fixed(a);
    },
    expand(tx_id) {
      if (this.expanded == tx_id) {
        this.expanded = null;
        return;
      }
      this.getTransaction(tx_id);
      this.expanded = tx_id;
    },
    getRemaining(period, day) {
      if (period - day > 0) {
        return `${period - day} payment`;
      } else {
        return "COMPLETE";
      }
    },

    async getTransaction(tx_id) {
      console.log("tx_id", tx_id);
      await admin
        .txrelation({ tx_id })
        .then((res) => {
          this.history_mining = [];
          this.ready = true;
          res.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.history_mining.push(e);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getListAll();
    this.getdata();
  },
};
</script>
