import api from "@/api/index";

export default {
  signUp(credentials) {
    return api().post("register", credentials);
  },
  signIn(credentials) {
    return api().post("login", credentials);
  },
  updatePassword(credentials) {
    return api().post("changepass", credentials);
  },
  resetPassword(credentials) {
    return api().post("resetpass", credentials);
  },
  resendemail(credentials) {
    return api().post("resendemail", credentials);
  }
};
