<template>
  <div class="">
    <div class="">
      <div class="m-1 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <card>
              <div class="table-responsive table-full-width">
                <a style="font-size: 24px; font-weight: 600"
                  >KYC Verification</a
                >
                <br />
                <br />
                <center>
                  <img
                    v-if="userKYC.status == 'wait'"
                    style="width: 20%; border: 15px solid #f7c039"
                    src="https://www.lifewire.com/thmb/wVfbriPF6UFJbvssAeb9aEOosnE=/2200x1467/filters:fill(auto,1)/best-search-engines-2483352-8cbda67ac96d4bc789f39e7ce61278d5.png"
                  />
                  <img
                    v-else-if="userKYC.status == 'approve'"
                    style="width: 20%; border: 15px solid #76f52e"
                    src="https://www.lifewire.com/thmb/wVfbriPF6UFJbvssAeb9aEOosnE=/2200x1467/filters:fill(auto,1)/best-search-engines-2483352-8cbda67ac96d4bc789f39e7ce61278d5.png"
                  />
                  <img
                    v-else-if="userKYC.status == 'reject'"
                    style="width: 20%; border: 15px solid #df4210"
                    src="https://www.lifewire.com/thmb/wVfbriPF6UFJbvssAeb9aEOosnE=/2200x1467/filters:fill(auto,1)/best-search-engines-2483352-8cbda67ac96d4bc789f39e7ce61278d5.png"
                  />

                  <br />
                  <br />
                  <div>
                    <div v-if="userKYC.status == 'wait'">
                      <p
                        style="
                          font-size: 26px;
                          font-weight: 600;
                          text-transform: uppercase;
                          color: #f7c039;
                        "
                      >
                        {{ userKYC.status }}
                      </p>
                    </div>
                    <div v-else-if="userKYC.status == 'approve'">
                      <p
                        style="
                          font-size: 26px;
                          font-weight: 600;
                          text-transform: uppercase;
                          color: #76f52e;
                        "
                      >
                        {{ userKYC.status }}
                      </p>
                    </div>
                    <div v-else-if="userKYC.status == 'reject'">
                      <p
                        style="
                          font-size: 26px;
                          font-weight: 600;
                          text-transform: uppercase;
                          color: #df4210;
                        "
                      >
                        {{ userKYC.status }}
                      </p>
                    </div>

                    <p style="font-weight: 600">
                      email :
                      <a style="font-size: 14px">{{ userKYC.email }}</a>
                    </p>
                    <p style="font-weight: 600">
                      name :
                      <a style="font-size: 14px">{{ userKYC.name }}</a>
                    </p>
                    <p style="font-weight: 600">
                      gender :
                      <a style="font-size: 14px">{{ userKYC.gender }}</a>
                    </p>
                    <p style="font-weight: 600">
                      phone :
                      <a style="font-size: 14px">{{ userKYC.phone }}</a>
                    </p>
                    <p style="font-weight: 600">
                      birthday :
                      <a style="font-size: 14px">{{ userKYC.birthday }}</a>
                    </p>
                    <p style="font-weight: 600">
                      address :
                      <a style="font-size: 14px">{{ userKYC.address }}</a>
                    </p>
                    <p style="font-weight: 600">
                      nationality :
                      <a style="font-size: 14px">{{ userKYC.nationality }}</a>
                    </p>
                  </div>
                  <fg-input
                    name="Remark"
                    label="Remark ( Case REJECT )"
                    v-model="remark"
                    placeholder="Remark"
                  >
                  </fg-input>
                </center>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-6">
                      <center>
                        <button
                          @click="updateKYC('approve')"
                          type="submit"
                          class="btn btn-fill btn-success"
                        >
                          <a style="font-size: 20px; font-weight: 600">
                            Approve
                          </a>
                        </button>
                      </center>
                    </div>
                    <div class="col-6">
                      <center>
                        <button
                          @click="updateKYC('reject')"
                          type="submit"
                          class="btn btn-fill btn-danger"
                        >
                          <a style="font-size: 20px; font-weight: 600">
                            Reject
                          </a>
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <center>
                <div v-for="url in ImgKYC" :key="url">
                  <img
                    v-if="userKYC.status"
                    style="width: 90%; border: 15px solid #f7c039"
                    :src="API_URL + '/images/uploads/' + url"
                  />
                  <br />
                </div>
              </center>
            </card>
          </div>
        </div>
      </div>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import Swal from "sweetalert2";

import { Table, TableColumn } from "element-ui";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";

import admin from "@/api/admin";
import user from "@/api/user";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      users: [],
      userKYC: {},
      user_id: this.$route.params.user_id || null,
      remark: "",
      user: {},
      ImgKYC: [],
      API_URL: API_URL,
    };
  },
  computed: mapGetters(["getUser"]),

  methods: {
    loadDataKYC() {
      axios
        .get(`${API_URL}/search/kyc/${this.user_id}`)
        .then((response) => {
          this.userKYC = response.data.userKYC;
          this.remark = this.userKYC.remark;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async updateKYC(status) {
      await admin
        .kycChangeStatus({
          kyc_id: this.userKYC._id,
          status: status,
          user_id: this.user_id,
          remark: this.remark,
        })
        .then(() => {
          this.loadDataKYC();
          Swal.fire({
            title: "Good job!",
            text: "update success!",
            type: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: "error!",
            type: "danger",
            confirmButtonClass: "btn btn-danger",
            buttonsStyling: false,
          });
        });
    },
    loadImgKYC() {
      axios
        .get(`${API_URL}/search/img/${this.user_id}`)
        .then((response) => {
          if (response.data.message == "no img") {
            this.uploadImgID = "";
          } else {
            this.ImgKYC = response.data.message.url;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.loadDataKYC();
    this.loadImgKYC();
  },
};
</script>
