import user from "@/api/user";

const state = {
  balance: JSON.parse(localStorage.getItem("Balance")) || {},
  wallet: {}
};

const getters = {
  getBalance: state => state.balance,
  getWallet: state => state.wallet
};

const actions = {
  async fetchBalance({ commit }) {
    await user.balance().then(response => {
      localStorage.setItem("Balance", JSON.stringify(response.data));
      commit("fetchBalance", response.data);
    });
  },
  async fetchWallet({ commit }) {
    const response = await user.wallet();
    commit("fetchWallet", response.data);
  },
  setBalance({ commit }, data) {
    localStorage.setItem("Balance", JSON.stringify(data));
    commit("setBalance", data);
  }
};

const mutations = {
  fetchBalance: (state, balance) => (state.balance = balance),
  fetchWallet: (state, wallet) => (state.wallet = wallet),
  setBalance: (state, balance) => (state.balance = balance)
};

export default {
  state,
  getters,
  actions,
  mutations
};
