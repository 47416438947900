import api from "@/api/index";

export default {
  balance(credentials) {
    return api().get("user/balances", credentials);
  },
  transactions(credentials) {
    return api().get("user/transactions", credentials);
  },
  logs(credentials) {
    return api().get("user/logs", credentials);
  },
  refers(credentials) {
    return api().get("user/refers", credentials);
  },
  update(credentials) {
    return api().post("user/update", credentials);
  },
  wallet(credentials) {
    return api().get("deposit/wallet", credentials);
  },
  twofactor(credentials) {
    return api().get("security/twofa", credentials);
  },
  enable2fa(credentials) {
    return api().post("security/enable2fa", credentials);
  },
  disable2fa(credentials) {
    return api().post("security/disable2fa", credentials);
  },
  mailconfirm(credentials) {
    return api().post("security/mailconfirm", credentials);
  },
  transfer(credentials) {
    return api().post("transfer", credentials);
  },
  transfer365(credentials) {
    return api().post("transfer365", credentials);
  },
  withdraw(credentials) {
    return api().post("withdraw", credentials);
  },
  withdrawhistory(credentials) {
    return api().get("withdraw/history", credentials);
  },
  cancelwithdraw(credentials) {
    return api().post("withdraw/cancel", credentials);
  },
  deposithistory(credentials) {
    return api().get("deposit/history", credentials);
  },
  rebate(credentials) {
    return api().post("rebate", credentials);
  },
  payment(credentials) {
    return api().post("pay", credentials);
  },
  setting(credentials) {
    return api().get("setting", credentials);
  },
  convertCoin(credentials) {
    return api().post("convert", credentials);
  },
  convertCoinCRD(credentials) {
    return api().post("convertCRD", credentials);
  },
  convertS9(credentials) {
    return api().post("convertS9", credentials);
  },
  paymentInvestor01(credentials) {
    return api().post("investor/packageAPPLE-PEE-PYP", credentials);
  },
  paymentInvestor02(credentials) {
    return api().post("investor/packageTSLA-FB-GOOGLE", credentials);
  },
  paymentInvestor03(credentials) {
    return api().post("investor/packageBTC-ETH-XRP", credentials);
  },
  paymentInvestor04(credentials) {
    return api().post("investor/packageGOLD-SILVER-OLI", credentials);
  },
  paymentInvestor05(credentials) {
    return api().post("investor/packageTSLA-BTC-GOLD", credentials);
  },
  buyOrder(credentials) {
    return api().post("buyOrder", credentials);
  },
  sellOrder(credentials) {
    return api().post("sellOrder", credentials);
  },
  usersList(credentials) {
    return api().get("user/list", credentials);
  },
  kycCreate(credentials) {
    return api().post("kyc/create", credentials);
  },
  takeBonus(credentials) {
    return api().post("takebonus", credentials);
  },
};
