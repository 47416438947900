<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="col-md-12">
        <card>
          <form @change="changeRadio()">
            <div class="row">
              <div class="col-3">
                <div class="row justify-content-end">
                  <radio label="ALL" v-model="radios.radio1">All List </radio>
                </div>
              </div>
              <div class="col-3">
                <div class="row justify-content-center">
                  <radio label="PROCESSING" v-model="radios.radio1"
                    >PROCESSING List</radio
                  >
                </div>
              </div>
              <div class="col-3">
                <div class="row justify-content-center">
                  <radio label="REJECTED" v-model="radios.radio1"
                    >REJECTED List</radio
                  >
                </div>
              </div>
              <div class="col-3">
                <radio label="COMPLETED" v-model="radios.radio1"
                  >COMPLETED List</radio
                >
              </div>
            </div>
          </form>
          <div slot="header">
            <p class="card-category">{{ radios.radio1 }}</p>
          </div>

          <div
            class="col-md-12 m-2"
            v-for="(item, index) in withdrawalsList"
            :key="index"
          >
            <template>
              <a href="#" v-on:click="expand(item.hash, item._id)">
                <div class="row">
                  <div class="col-3">
                    {{ item.createdAt }}
                  </div>
                  <div class="col-2">
                    {{ item.amount }}
                  </div>
                  <div class="col-3">
                    {{ item.currency }}
                  </div>
                  <div class="col-4">
                    <a
                      v-if="item.status == 'REJECTED'"
                      style="color: red; font-weight: 600"
                    >
                      {{ item.status }}
                    </a>

                    <a
                      v-else-if="item.status == 'PROCESSING'"
                      style="color: orange; font-weight: 600"
                    >
                      {{ item.status }}
                    </a>
                    <a
                      v-else-if="item.status == 'COMPLETED'"
                      style="color: green; font-weight: 600"
                    >
                      {{ item.status }}
                    </a>
                  </div>
                </div>
              </a>
            </template>

            <div v-if="expanded == item.hash">
              <br />
              <card class="stacked-form">
                <center>
                  <i
                    class="fa fa-user"
                    style="font-size: 52px; color: orange"
                  ></i>
                  <br />
                  <a style="font-size: 20px; font-weight: 600">
                    {{ dataWithdrawals.status }}
                  </a>
                  <br />

                  <vue-qrcode :value="dataWithdrawals.address" width="200" />

                  <br />

                  {{ dataWithdrawals.address }}
                </center>

                <div>
                  <a style="font-size: 24px; font-weight: 600">
                    {{ dataWithdrawals.currency }}
                  </a>
                  <fg-input
                    v-model="dataWithdrawals._id"
                    label="withdrawals _id"
                    placeholder="Enter withdrawals id"
                    disabled
                  >
                  </fg-input>
                  <fg-input
                    v-model="dataWithdrawals.amount"
                    :label="'amount ' + dataWithdrawals.currency"
                    disabled
                  >
                  </fg-input>
                  <fg-input
                    v-model="dataWithdrawals.address"
                    label="to address"
                    disabled
                  >
                  </fg-input>

                  <fg-input v-model="text_id" label="text_id"> </fg-input>

                  <a
                    v-tooltip.top-center="'address'"
                    class="btn btn-warning btn-link btn-xs"
                    :href="
                      'https://btc1.trezor.io/address/' +
                      dataWithdrawals.address
                    "
                    target="_blink"
                  >
                    address {{ dataWithdrawals.address }}
                  </a>

                  <br />

                  <a
                    v-tooltip.top-center="'user_id'"
                    class="btn btn-warning btn-link btn-xs"
                    :href="'/adminprofile/' + dataWithdrawals.user_id"
                    target="_blink"
                  >
                    user {{ dataWithdrawals.user_id }}
                  </a>

                  <div v-if="changeStatus" class="row justify-content-center">
                    <div class="col-6">
                      <center>
                        <button
                          @click="updateWithdrawals('COMPLETED')"
                          class="btn btn-fill btn-primary"
                        >
                          Approve
                        </button>
                      </center>
                    </div>
                    <div class="col-6">
                      <center>
                        <button
                          @click="updateWithdrawals('REJECTED')"
                          class="btn btn-fill btn-danger"
                        >
                          Reject
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </card>
            </div>

            <hr />
          </div>
        </card>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import user from "@/api/user";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

import admin from "@/api/admin";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";

import VueQrcode from "vue-qrcode";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Radio,
    VueQrcode,
  },
  data() {
    return {
      users: {},
      id: "",
      email: "",
      showData: false,
      withdrawalsList: [],
      dataWithdrawals: {},
      radios: {
        radio1: "ALL",
      },
      text_id: "",
      changeStatus: false,
      expanded: null,
    };
  },
  computed: mapGetters(["getUser"]),

  methods: {
    ...mapActions([]),

    tableRowClassName(row, index) {
      if (index === 0) {
        return "success";
      } else if (index === 2) {
        return "info";
      } else if (index === 4) {
        return "danger";
      } else if (index === 6) {
        return "warning";
      }
      return "";
    },

    expand(tx_id, item_id) {
      if (this.expanded == tx_id) {
        this.expanded = null;
        return;
      }
      this.expanded = tx_id;

      this.id = item_id;

      this.searchWithdrawals();
    },

    async getWithdrawalsListAll() {
      this.withdrawalsList = [];
      await admin.allwithdraw().then((res) => {
        res.data.forEach((e) => {
          e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

          this.withdrawalsList.push(e);
        });
      });
    },
    searchWithdrawals() {
      this.text_id = null;
      if (this.id != "") {
        axios
          .get(`${API_URL}/withdraw/search/${this.id}`)
          .then((response) => {
            this.dataWithdrawals = response.data;

            if (response.data.status == "PROCESSING") {
              this.changeStatus = true;
            } else {
              this.changeStatus = false;
            }

            this.text_id = response.data.tx_id;

            axios
              .get(`${API_URL}/user/detail/${response.data.user_id}`)
              .then((response) => {
                this.users = response.data.users;
                this.showData = true;
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {});
      } else {
        alert("ใส่ข้อมูลก่อน");
      }
    },
    async updateWithdrawals(status) {
      await admin
        .updatewithdraw({
          _id: this.dataWithdrawals._id,
          status,
          tx_id: this.text_id,
        })
        .then((res) => {
          this.getWithdrawalsListAll();

          this.changeStatus = false;

          this.expanded = null;

          Swal.fire({
            title: `${status}!`,
            text: `Withdrawals ${status}!`,
            type: "success",
            confirmButtonClass: "btn btn-success",
            buttonsStyling: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async changeRadio() {
      this.withdrawalsList = [];

      if (this.radios.radio1 == "ALL") {
        this.getWithdrawalsListAll();
      } else if (this.radios.radio1 == "REJECTED") {
        this.changeRadioREJECTED();
      } else if (this.radios.radio1 == "PROCESSING") {
        this.changeRadioPROCESSING();
      } else if (this.radios.radio1 == "COMPLETED") {
        this.changeRadioCOMPLETED();
      }
    },
    async changeRadioCOMPLETED() {
      this.withdrawalsList = [];

      await admin.allwithdraw().then((res) => {
        res.data.forEach((e) => {
          if (e.status == "COMPLETED") {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.withdrawalsList.push(e);
          }
        });
      });
    },
    async changeRadioREJECTED() {
      this.withdrawalsList = [];

      await admin.allwithdraw().then((res) => {
        res.data.forEach((e) => {
          if (e.status == "REJECTED") {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.withdrawalsList.push(e);
          }
        });
      });
    },
    async changeRadioPROCESSING() {
      this.withdrawalsList = [];

      await admin.allwithdraw().then((res) => {
        res.data.forEach((e) => {
          if (e.status == "PROCESSING") {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.withdrawalsList.push(e);
          }
        });
      });
    },
  },
  created() {
    this.getWithdrawalsListAll();
  },
};
</script>
