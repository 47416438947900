<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="m-1 mt-3">
        <div class="row">
          <div class="col-md-6">
            <card>
              <div slot="header">
                <h4 class="card-title">Users</h4>
                <p class="card-category">All User</p>
              </div>
              <div class="table-responsive table-full-width">
                <el-table class="table-striped" :data="users">
                  <el-table-column label="ID" property="_id"></el-table-column>
                  <el-table-column label="" property="email"></el-table-column>
                </el-table>
              </div>
            </card>
          </div>
          <div class="col-md-6">
            <card class="stacked-form">
              <center>
                <i
                  class="fa fa-user"
                  style="font-size: 52px; color: orange"
                ></i>
                <br />
                <a style="font-size: 24px; font-weight: 600">
                  <a style="font-size: 14px">Users</a>
                </a>
              </center>

              <div>
                <fg-input
                  v-model="id"
                  label="User_ic or Email"
                  placeholder="Enter id or email"
                >
                </fg-input>

                <a style="font-weight: 600">Coin </a>

                <el-select
                  class="select-danger"
                  size="large"
                  placeholder="Single Select"
                  v-model="selects.simpleCoin"
                >
                  <el-option
                    v-for="option in selects.currency"
                    class="select-danger"
                    :value="option.coin"
                    :label="option.coin"
                    :key="option.coin"
                  >
                  </el-option>
                </el-select>

                <br />

                <br />
                <br />
                <br />
                <br />
                <center>
                  <button
                    @click="searchUser()"
                    style="background-color: green"
                    class="btn btn-fill btn-info"
                  >
                    Search
                  </button>
                </center>
              </div>
            </card>
            <card class="stacked-form">
              <fg-input
                v-model="user_id"
                label="user_id"
                placeholder="user_id"
                disabled
              >
              </fg-input>
              <fg-input
                v-model="email"
                label="email"
                placeholder="email"
                disabled
              >
              </fg-input>
              <fg-input
                v-model="coinBefore"
                :label="selects.simpleCoin + ' ' + 'Before'"
                :placeholder="selects.simpleCoin + ' ' + 'Before'"
                disabled
              >
              </fg-input>
              <fg-input
                v-model="lastBTC"
                :label="'Last amount' + ' ' + selects.simpleCoin"
                :placeholder="'Last amount' + ' ' + selects.simpleCoin"
                disabled
              >
              </fg-input>

              <div>
                <fg-input
                  v-model="coinAfter"
                  :label="'Add amount' + ' ' + selects.simpleCoin"
                  :placeholder="'Add amount' + ' ' + selects.simpleCoin"
                >
                </fg-input>
                <fg-input v-model="note" label="Note" placeholder="Remake">
                </fg-input>

                <center>
                  <button @click="update()" class="btn btn-info">
                    Update {{ selects.simpleCoin }}
                  </button>
                </center>
              </div>
            </card>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn, Select, Option } from "element-ui";
import user from "@/api/user";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";

import admin from "@/api/admin";

import Swal from "sweetalert2";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      users: [],
      id: "",
      user: {},
      coinBefore: "",
      coinAfter: "",
      lastBTC: "",
      user_id: "",
      email: "",
      note: "Add Transactions by Admin",
      selects: {
        simpleCoin: "",
        currency: [
          { value: "Bahasa Indonesia", label: "Bahasa Indonesia" },
          { value: "Bahasa Melayu", label: "Bahasa Melayu" },
          { value: "Català", label: "Català" },
          { value: "Dansk", label: "Dansk" },
          { value: "Deutsch", label: "Deutsch" },
        ],
        multiple: "ARS",
      },
    };
  },
  computed: mapGetters(["getUser"]),

  methods: {
    ...mapActions([]),

    tableRowClassName(row, index) {
      if (index === 0) {
        return "success";
      } else if (index === 2) {
        return "info";
      } else if (index === 4) {
        return "danger";
      } else if (index === 6) {
        return "warning";
      }
      return "";
    },
    async getUsersList() {
      await user.usersList().then((res) => {
        this.users = res.data.users;
      });
    },
    async searchUser() {
      this.lastBTC = "";
      if (this.id != "") {
        let user = this.users.find((u) => {
          return u.email == this.id || u._id == this.id;
        });

        if (user != undefined && user != "undefined") {
          axios
            .get(`${API_URL}/user/detail/${user._id}`)
            .then((response) => {
              this.email = response.data.users.email;
            })
            .catch((error) => {
              console.log(error);
            });
          axios
            .get(`${API_URL}/searchcoin/${this.selects.simpleCoin}/${user._id}`)
            .then((response) => {
              if (response.data != "Create success") {
                this.coinBefore = response.data.funds.amount;
                this.coinAfter = 0;
                this.user_id = response.data.funds.user_id;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert("ข้อมูลไม่ถูกต้อง");
        }
      } else {
        alert("ใส่ข้อมูลก่อน");
      }
    },
    fecthAmount() {
      axios
        .get(`${API_URL}/searchcoin/${this.selects.simpleCoin}/${this.id}`)
        .then((response) => {
          if (response.data != "Create success") {
            this.lastBTC = response.data.funds.amount;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async update() {
      if (this.user_id != "") {
        this.coinAfter = parseFloat(this.coinAfter).toFixed(8);
        await admin
          .addmoney({
            user_id: this.user_id,
            email: this.email,
            amount: this.coinAfter,
            currency: this.selects.simpleCoin,
            note: this.note,
          })
          .then((response) => {
            this.coinAfter = null;
            this.fecthAmount();

            Swal.fire({
              title: `Add monney`,
              text: `Update Success!`,
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
            });
          })
          .catch((error) => {});
      }
    },
    async getCurrency() {
      await admin
        .currency()
        .then((response) => {
          this.selects.currency = response.data;
        })
        .catch((error) => {});
    },
  },
  created() {
    this.getUsersList();
    this.getCurrency();
  },
};
</script>
