<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="m-1 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <card class="stacked-form">
              <center>
                <a style="font-size: 24px; font-weight: 600">
                  <a style="font-size: 14px">View User</a>
                </a>
              </center>

              <div>
                <fg-input
                  v-model="email"
                  label="Email"
                  placeholder="Enter email"
                >
                </fg-input>

                <center>
                  <button
                    style="background-color: green"
                    class="btn btn-fill btn-info"
                    @click="getdata()"
                  >
                    Search
                  </button>
                </center>
              </div>
            </card>
          </div>

          <div v-if="user != null" class="col-md-8">
            <card class="stacked-form">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-2">
                      <div
                        class="row align-items-center justify-content-center"
                      >
                        <br />
                        <br />
                        <i
                          class="fa fa-user"
                          style="font-size: 32px; color: orange"
                        ></i>
                      </div>
                    </div>
                    <div class="col-10" style="font-size: 14px">
                      {{ user.email }}
                      <br />
                      user_id : {{ user._id }}
                      <br />
                      refer_id : {{ user.refer_id }}
                      <br />
                      CreatedAt : {{ timeformatt(user.createdAt) }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <center>
                    <div>
                      <a
                        :href="`/admintransactions/${user.email}`"
                        class="btn btn-success"
                        size="lg"
                      >
                        Transaction
                      </a>
                    </div>
                    <div>
                      <a
                        :href="`/adminprofile/${user._id}`"
                        target="_blink"
                        class="btn btn-success"
                        size="lg"
                        >KYC</a
                      >
                    </div>
                  </center>
                </div>
              </div>
            </card>
            <p style="font-size: 20px; font-weight: 600">User Funds</p>
            <div class="row" style="font-weight: 600">
              <div class="col-3">
                <div class="row justify-content-center">Currency</div>
              </div>
              <div class="col-3">
                <div class="row justify-content-center">Balance</div>
              </div>
              <div class="col-3">
                <div class="row justify-content-center">In staking</div>
              </div>
              <div class="col-3">
                <div class="row justify-content-center">Available</div>
              </div>
            </div>
            <div
              style="font-size: 14px"
              v-for="b in funds"
              v-bind:key="b.id"
              class="row"
            >
              <div class="col-3">
                <div class="row justify-content-center">{{ b.coin }}</div>
              </div>
              <div class="col-3">
                <div class="row justify-content-center">
                  <div v-if="b.coin == 'BTC'">
                    {{ fixed4(b.balance) }}
                  </div>
                  <div v-else>
                    <div v-if="b.coin == 'S9THs'">
                      {{ parseFloat(fixed2(b.balance)) + amounMiningS9 }}
                    </div>
                    <div v-else-if="b.coin == 'S19THs'">
                      {{ parseFloat(fixed2(b.balance)) + amounMiningS19 }}
                    </div>
                    <div v-else>
                      {{ fixed2(b.balance) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="row justify-content-center">
                  <div v-if="b.coin == 'S9THs'">
                    {{ amounMiningS9 }}
                  </div>
                  <div v-if="b.coin == 'S19THs'">
                    {{ amounMiningS19 }}
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="row justify-content-center">
                  <div v-if="b.coin == 'BTC'">
                    {{ fixed4(b.available) }}
                  </div>
                  <div v-else>
                    {{ fixed2(b.available) }}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import user from "@/api/user";

import { Table, TableColumn } from "element-ui";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";

import investbox from "@/api/investbox";

import Swal from "sweetalert2";
import func from "@/widgets/func";

import moment from "moment";

import admin from "@/api/admin";

export default {
  components: {
    TopNavbar,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      total_usd: 0,
      address: this.$route.params.address || null,
      ready: false,
      loading: false,
      filter: "",
      user: null,
      deposit: [],
      invests: [],
      funds: [],
      wallet: [],
      amounMiningS9: 0,
      amounMiningS19: 0,
      API_URL: API_URL,
      email: "",
    };
  },
  computed: mapGetters(["getUser", "getCoins"]),

  methods: {
    timeformatt(time) {
      return moment(time).format("YYYY-MM-DD, HH:mm:ss");
    },
    fixed(a) {
      return func.fixed(a);
    },
    fixed4(a) {
      return func.fixed8(a);
    },
    fixed2(a) {
      return parseFloat(a).toFixed(2);
    },
    async getdata() {
      await admin
        .user({ email: this.email })
        .then((res) => {
          this.user = res.data.user;

          this.funds = res.data.funds;

          this.getMinig();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMinig() {
      let inMiningS9 = 0;
      let inMiningS19 = 0;

      axios
        .get(`${API_URL}/contract/search/${this.user._id}`)
        .then((res) => {
          res.data.map((con) => {
            if (con.status != "COMPLETED") {
              if (con.currencyIN == "S9THs") {
                inMiningS9 = inMiningS9 + parseFloat(con.amount);
              } else if (con.currencyIN == "S19THs") {
                inMiningS19 = inMiningS19 + parseFloat(con.amount);
              }
            }
          });

          this.amounMiningS9 = inMiningS9;
          this.amounMiningS19 = inMiningS19;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    if (this.address != null) {
      this.email = this.address;
      this.getdata();
    }
  },
};
</script>
