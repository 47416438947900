<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'Summary',
            icon: 'fa fa-globe',
            path: '/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'MINING',
            icon: 'fa fa-line-chart',
            path: '/managepool',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'FARMING',
            icon: 'fa fa-ship',
            path: '/farm',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'STAKING',
            icon: 'fa fa-bolt',
            path: '/staking',
          }"
        ></sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/transection',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSFER',
            icon: 'fa fa-exchange',
            path: '/transfer',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY FUNDS',
            icon: 'fa fa-money',
            path: '/fund',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'AFFILIATE',
            icon: 'fa fa-sitemap',
            path: '/affiliate',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'MY PROFILE',
            icon: 'fa fa-address-card',
            path: '/profile',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'SETTINGS', icon: 'fa fa-cog' }">
          <sidebar-item
            :link="{ name: 'Change Password', path: '/changepass' }"
          ></sidebar-item>
        </sidebar-item>
        <br />
        <center>
          <button class="btn btn-danger" @click="signout()">
            <a style="font-size: 14px; font-weight: 600"> LOGOUT </a>
          </button>
        </center>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar title="Deposit HBX"></top-navbar>

      <div class="row justify-content-end">
        <div class="mr-5 mt-3">
          <a href="/history" style="color: #14b3f9; font-weight: 600"
            >History</a
          >
        </div>
      </div>

      <div class="m-3 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <card class="stacked-form" title="">
              <form method="#" action="#" @submit.prevent>
                <center>
                  <img
                    style="max-width: 30px"
                    :src="require(`@/assets/tokens/HBX.png`)"
                  />
                  <a style="font-size: 18px">
                    YOUR <a style="font-weight: 600">HBX</a> DEPOSIT ADDRESS
                    <br />
                    <a style="font-weight: 600"> BEP20 ( BSC ) </a>
                  </a>
                  <br />
                  <vue-qrcode :value="getWallet['HBX'].address" width="300" />
                  <div style="font-size: 14px">
                    <a
                      >Address <a style="font-weight: 600">BEP20 ( BSC )</a>:
                    </a>

                    <input
                      type="hidden"
                      id="text-address"
                      :value="getWallet['HBX'].address"
                    />
                    <a style="font-weight: 600">{{
                      getWallet["HBX"].address
                    }}</a>
                  </div>
                  <div>
                    <br />

                    <button
                      style="background-color: green"
                      type="submit"
                      class="btn btn-fill btn-info"
                      @click="copyAddress()"
                    >
                      {{ msg }}
                    </button>
                  </div>
                  <div></div>
                </center>
                <div class="m-4">
                  <p style="font-weight: 600">Please note</p>
                  <div style="font-size: 14px" class="m-1">
                    <ul>
                      <li>
                        Send only HashBX this deposit address. Sending any other
                        coin or token to this address may result in the loss of
                        your deposit.
                      </li>
                      <li>
                        Use your HashBX wallet to deposit. Deposits are credited
                        after 12 confirmation.
                      </li>
                      <li>
                        HashBX transactions can take up to 10 minutes to
                        process.
                      </li>
                    </ul>
                  </div>
                </div>

                <br />
              </form>
            </card>
          </div>
        </div>
      </div>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn } from "element-ui";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
import user from "@/api/user";
import { API_URL } from "../../../config";
import investbox from "@/api/investbox";

import VueQrcode from "vue-qrcode";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueQrcode,
  },
  data() {
    return {
      address: "",
      msg: "Copy",
    };
  },
  computed: mapGetters(["getUser", "getBalance", "getWallet"]),
  methods: {
    ...mapActions(["Logout", "fetchWallet"]),

    signout() {
      this.Logout();
      this.$router.push("/");
    },
    copyAddress() {
      let textToCopy = document.querySelector("#text-address");
      textToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      textToCopy.select();

      try {
        document.execCommand("copy");
        this.msg = "Copied";
      } catch (err) {}

      /* unselect the range */
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
  created() {
    this.fetchWallet();
  },
};
</script>
