<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div class="m-1 mt-3">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <card class="stacked-form">
              <fg-input
                disabled
                v-model="type_invest"
                label="type_invest"
                placeholder="type_invest"
              >
              </fg-input>
              <fg-input
                v-model="currencyIN"
                label="currencyIN"
                placeholder="currencyIN"
              >
              </fg-input>
              <fg-input
                v-model="currencyOUT"
                label="currencyOUT"
                placeholder="currencyOUT"
                disabled
              >
              </fg-input>

              <fg-input
                v-model="currencyCOST"
                label="currencyCOST"
                placeholder="currencyCOST"
                disabled
              >
              </fg-input>
              <fg-input
                v-model="cost_ratio"
                label="cost_ratio"
                placeholder="cost_ratio"
              >
              </fg-input>
              <fg-input
                v-model="period"
                label="period ( days )"
                placeholder="period"
              >
              </fg-input>
              <fg-input
                v-model="min_invest"
                label="min_invest ( S9th )"
                placeholder="min_invest"
              >
              </fg-input>
              <fg-input v-model="total" label="total" placeholder="total">
              </fg-input>

              Power hash {{ powerHash / 1000 }} TH/s

              <div>
                <br />
                <center>
                  <button @click="createInvest()" class="btn btn-info">
                    Create Invest
                  </button>
                </center>
              </div>
            </card>
          </div>
        </div>
      </div>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import { Table, TableColumn, Select, Option } from "element-ui";
import user from "@/api/user";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";

import investbox from "@/api/investbox";

import Swal from "sweetalert2";

import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
  Radio,
} from "src/components/index";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
    Checkbox,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      type_invest: "MINING",
      currencyIN: "S9THs",
      currencyOUT: "BTC",
      currencyCOST: "CRD",
      ratio: "0.00000618706",
      cost_ratio: "0.4",
      period: "15",
      fee: "0",
      com: "0",
      min_invest: "1",
      total: "1000",
      listInvest: {},
      powerHash: "0",
    };
  },
  computed: mapGetters(["getUser"]),

  methods: {
    ...mapActions([]),

    tableRowClassName(row, index) {
      if (index === 0) {
        return "success";
      } else if (index === 2) {
        return "info";
      } else if (index === 4) {
        return "danger";
      } else if (index === 6) {
        return "warning";
      }
      return "";
    },
    async createInvest() {
      await investbox
        .create({
          type: this.type_invest,
          currencyIN: this.currencyIN,
          currencyOUT: this.currencyOUT,
          currencyCOST: this.currencyCOST,
          ratio: this.ratio,
          cost_ratio: this.cost_ratio,
          period: this.period,
          fee: this.fee,
          com: this.com,
          min_invest: this.min_invest,
          total: this.total,
          powerHash: this.powerHash,
        })
        .then(() => {
          this.investList();

          setTimeout(() => {
            Swal.fire({
              title: `Success`,
              text: `Update Success!`,
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
            });
          }, 500);
        })
        .catch((error) => {
          alert("err");
        });
    },
    async investList() {
      await investbox
        .list()
        .then((res) => {
          this.listInvest = res.data;
        })
        .catch((error) => {
          alert("err");
        });
    },
    fetchPowerHash() {
      axios
        .get(`https://blockchain.info/q/hashrate`)
        .then((response) => {
          this.powerHash = parseFloat(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.investList();
    this.fetchPowerHash();
  },
};
</script>
