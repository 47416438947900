<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar>
      <template slot-scope="" slot="links">
        <sidebar-item
          :link="{
            name: 'ADD MONEY',
            icon: 'fa fa-money',
            path: '/adminaddmoney',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'KYC Verification',
            icon: 'fa fa-user',
            path: '/adminkyc',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Withdrawals List',
            icon: 'fa fa-key',
            path: '/adminwithdrawalslist',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Contract',
            icon: 'fa fa-signal',
            path: '/adminccontract',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'TRANSACTIONS',
            icon: 'fa fa-calendar',
            path: '/admintransactions',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'View User',
            icon: 'fa fa-user',
            path: '/adminviewuser',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'All Deposit',
            icon: 'fa fa-gift',
            path: '/admindepositall',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fund Summary',
            icon: 'fa fa-bar-chart',
            path: '/adminfund',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <br />

      <div class="row justify-content-center">
        <div class="col-md-10">
          <card class="stacked-form" title="">
            <form method="#" action="#" @submit.prevent>
              <center>
                <a style="font-size: 18px; font-weight: 600">
                  Deposit History
                </a>
                <br />
              </center>

              <br />

              <div class="m-1">
                <div v-for="(item, index) in table_deposit" :key="index">
                  <div class="row" v-on:click="expand(item.tx_id)">
                    <div class="col-8">
                      <img
                        style="max-width: 40px"
                        :src="require(`@/assets/tokens/${item.currency}.png`)"
                      />
                      <a
                        v-if="item.status == 'COMPLETED'"
                        class="p-1"
                        style="
                          background-color: green;
                          color: white;
                          font-size: 14px;
                          font-weight: 600;
                          border-radius: 5px;
                        "
                      >
                        {{ item.status }}
                      </a>
                      <a
                        v-else-if="item.status == 'INCOMMING'"
                        class="p-1"
                        style="
                          background-color: #e5bc03;
                          color: white;
                          font-size: 14px;
                          font-weight: 600;
                          border-radius: 5px;
                        "
                      >
                        {{ item.status }}
                      </a>
                      <a
                        v-else-if="item.status == 'REJECTED'"
                        class="p-1"
                        style="
                          background-color: red;
                          color: white;
                          font-size: 14px;
                          font-weight: 600;
                          border-radius: 5px;
                        "
                      >
                        {{ item.status }}
                      </a>
                      <br />
                      <a style="font-size: 14px">
                        {{ item.createdAt }}
                      </a>
                    </div>
                    <div class="col-4">
                      <a v-if="item.topool != true">
                        <i class="fa fa-database" style="color: #e57e03"></i>
                      </a>
                      {{ item.amount }}
                    </div>
                  </div>

                  <div v-if="expanded == item.tx_id">
                    <div class="m-4">
                      <div style="font-size: 14px">
                        <div class="row">
                          <div class="col-2">Tx ID:</div>
                          <div class="col-10">
                            <a
                              target="_blank"
                              :href="'https://etherscan.io/tx/' + item.tx_id"
                            >
                              {{ item.tx_id }}
                            </a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-2">Address:</div>
                          <div class="col-10">
                            <a
                              target="_blank"
                              :href="
                                'https://etherscan.io/address/' + item.tx_id
                              "
                            >
                              {{ item.address }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="m-2">
                      <button
                        type="submit"
                        class="btn btn-fill btn-info"
                        @click="ViewUser(item.address)"
                      >
                        View User
                      </button>

                      <button
                        style="background-color: #70cfa3; margin-left: 10px"
                        type="submit"
                        class="btn btn-fill btn-info"
                        @click="sendtopool(item._id)"
                      >
                        Send to Pool
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://kit.fontawesome.com/a076d05399.js"></script>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import user from "@/api/user";

import { Table, TableColumn } from "element-ui";

import { mapGetters, mapActions } from "vuex";
import { API_URL } from "../../config";
import axios from "axios";

import investbox from "@/api/investbox";

import Swal from "sweetalert2";

import moment from "moment";

import admin from "@/api/admin";

export default {
  components: {
    TopNavbar,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      contractList: [],
      address: "",
      msg: "Copy",
      expanded: "",
      table_deposit: [],
    };
  },
  computed: mapGetters(["getUser", "getWallet"]),

  methods: {
    ...mapActions(["Logout", "fetchWallet"]),

    getDepositHistory() {
      this.table_deposit = [];

      admin
        .alldeposit()
        .then((response) => {
          this.ready = true;
          response.data.forEach((e) => {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

            this.table_deposit.push(e);
          });
        })
        .catch((error) => {});
    },
    expand(id) {
      if (this.expanded == id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },
    ViewUser(address) {
      this.$router.push(`/adminviewuser/${address}`);
    },
    async sendtopool(id) {
      await admin
        .sendtopool({
          _id: id,
        })
        .then((res) => {
          this.getDepositHistory();
        })
        .catch((error) => {
          this.getDepositHistory();
        });
    },
  },
  created() {
    this.fetchWallet();
    this.getDepositHistory();
  },
};
</script>
