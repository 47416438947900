module.exports = {
  COMPANY: "hashbx.com",
  // SITE_URL: "http://localhost:8080",
  SITE_URL: "https://hashbx.web.app",

  VERSION: "2.0.0",
  RECPATCHA_KEY: "6Ld2KDcaAAAAADG5Qbj3MbM7dcekKktt4-h9_vgp",
  // API_URL: "http://localhost:5000",
  // API_URL: "https://api.hashbx.com",
  API_URL: "https://hashbx.autorebalance.co",
};
