var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Summary',
          icon: 'fa fa-globe',
          path: '/overview',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'MINING',
          icon: 'fa fa-line-chart',
          path: '/managepool',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'FARMING',
          icon: 'fa fa-ship',
          path: '/farm',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'TRANSACTIONS',
          icon: 'fa fa-calendar',
          path: '/transection',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'TRANSFER',
          icon: 'fa fa-exchange',
          path: '/transfer',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'MY FUNDS',
          icon: 'fa fa-money',
          path: '/fund',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'AFFILIATE',
          icon: 'fa fa-sitemap',
          path: '/affiliate',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'MY PROFILE',
          icon: 'fa fa-address-card',
          path: '/profile',
        }}}),_c('sidebar-item',{attrs:{"link":{ name: 'SETTINGS', icon: 'fa fa-cog' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Change Password', path: '/changepass' }}})],1),_c('br'),_c('center',[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.signout()}}},[_c('a',{staticStyle:{"font-size":"14px","font-weight":"600"}},[_vm._v(" LOGOUT ")])])])],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar',{attrs:{"title":"Change Password"}}),_c('div',{staticClass:"m-3 mt-3"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-6"},[_c('card',{staticClass:"stacked-form",attrs:{"title":""}},[_c('a',{staticStyle:{"color":"#14b3f9","font-size":"18px"}},[_vm._v(" Change Password ")]),_c('form',{attrs:{"method":"#","action":"#"},on:{"submit":function($event){$event.preventDefault();}}},[_c('a',{staticStyle:{"font-size":"14px"}},[_vm._v(" Weak passwords can easily lead to unauthorised access of your account and loss of funds ")]),_c('br'),_c('br'),_c('div',[_c('fg-input',{attrs:{"label":"Current Password","placeholder":"Current Password"},model:{value:(_vm.currentPass),callback:function ($$v) {_vm.currentPass=$$v},expression:"currentPass"}}),_c('fg-input',{attrs:{"label":"New Password","placeholder":"New Password"},model:{value:(_vm.newPass),callback:function ($$v) {_vm.newPass=$$v},expression:"newPass"}}),_c('fg-input',{attrs:{"label":"Confirm Password","placeholder":"Confirm Password"},model:{value:(_vm.confirmPass),callback:function ($$v) {_vm.confirmPass=$$v},expression:"confirmPass"}}),_c('br'),_c('center',[(_vm.changePass)?_c('div',[_c('button',{staticClass:"btn btn-fill btn-info",attrs:{"type":"submit","disabled":""}},[_vm._v(" Change Password ")])]):_c('div',[_c('button',{staticClass:"btn btn-fill btn-info",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Change Password ")])])])],1)])])],1)])]),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }