<template>
  <auth-layout>
    <div class="m-3 mt-2">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <card class="stacked-form" title="REGISTER">
            <div>
              <fg-input
                label="Email address"
                type="email"
                placeholder="Enter email"
                v-model="email"
              >
              </fg-input>
              <fg-input
                type="password"
                label="Passward"
                placeholder="Enter Passward"
                v-model="password"
              >
              </fg-input>
              <fg-input
                type="password"
                label="Confirm Passward"
                placeholder="Confirm Passward"
                v-model="confirmPass"
              >
              </fg-input>

              <center>
                <button
                  @click="createAccount()"
                  type="submit"
                  class="btn btn-fill btn-info"
                >
                  Create Account
                </button>
              </center>
              <a style="font-size: 14px;"
                >ref:
                <a style="font-weight: 500; font-size: 16px"> {{ ref }}</a></a
              >
            </div>
          </card>
        </div>
      </div>
      <br />
      <center></center>
    </div>
  </auth-layout>
</template>
<script>
import { FadeRenderTransition } from "src/components/index";
import AuthLayout from "./AuthLayoutAff.vue";
import auth from "@/api/auth";
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";

import Vue from "vue";
import { Dialog, Table, TableColumn, MessageBox } from "element-ui";

Vue.prototype.$confirm = MessageBox.confirm;

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      pricePer: "300.000",
      email: "",
      password: "",
      confirmPass: "",
      modals: {
        confirm: false,
      },
      ref: this.$route.params.ref,
    };
  },
  methods: {
    ...mapActions([
      "setToken",
      "setUser",
      "setSecure",
      "fetchBalance",
      "setBalance",
    ]),
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
    async createAccount() {
      Swal.fire({
        title: "Risk",
        html:
          "<div>" +
          "Usability contains risk. We are not promising returns, income or profit. Investment contain risk  like price risk , platform risk and others risky situations. Asset invested increase , decrease or down to zero users must study all information before investing" +
          "<br />  <a href='/terms' target='_blink'>Terms & Conditions</a>" +
          "</div>",

        type: "warning",
        confirmButtonText: "Accepted",
        confirmButtonClass: "btn btn-success btn-fill",
        buttonsStyling: false,
      }).then((value) => {
        if (value.isConfirmed == true) {
          this.signUpUser();
        }
      });
    },
    async signUpUser() {
      if (this.password == this.confirmPass && this.password != "") {
        await auth
          .signUp({
            email: this.email,
            password: this.password,
            ref: this.ref,
          })
          .then(() => {
            Swal.fire({
              title: "Register Success",
              text: "Register Success!",
              type: "success",
              confirmButtonClass: "btn btn-success",
              buttonsStyling: false,
            });
            this.$router.push("/login");
          })
          .catch((error) => {
            alert(`${error.response.data.error}`);
          });
      } else {
        alert(" Password did match ");
      }
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style>
.el-dialog {
  width: 50%;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 90%;
  }
}
</style>
