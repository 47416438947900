import { render, staticRenderFns } from "./DepositAll.vue?vue&type=template&id=f5b9aec0&"
import script from "./DepositAll.vue?vue&type=script&lang=js&"
export * from "./DepositAll.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports