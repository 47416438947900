var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("Please tell us more about yourself.")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"name","error":failed ? 'The First Name field is required' : null,"hasSuccess":passed,"placeholder":"First name and last name","label":"First name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1)]),_c('fg-input',{attrs:{"label":"Gender"}},[_c('div',[_c('checkbox',{attrs:{"inline":true},model:{value:(_vm.male),callback:function ($$v) {_vm.male=$$v},expression:"male"}},[_vm._v("MALE")]),_c('checkbox',{staticClass:"ml-2",attrs:{"inline":true},model:{value:(_vm.female),callback:function ($$v) {_vm.female=$$v},expression:"female"}},[_vm._v("FEMALE")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"birthday","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"birthday","error":failed ? 'The birthday field is required' : null,"hasSuccess":passed,"placeholder":"01/02/2559"},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"passpot","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"label":"International Passport / National ID card","error":failed ? 'The Passport field is required' : null,"hasSuccess":passed,"placeholder":"Passport"},model:{value:(_vm.passport),callback:function ($$v) {_vm.passport=$$v},expression:"passport"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"Address","label":"Address","error":failed ? 'The Address field is required' : null,"hasSuccess":passed,"placeholder":"Address"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"Phone Number","label":"Phone Number","error":failed ? 'The Phone Number field is required' : null,"hasSuccess":passed,"placeholder":"Phone Number"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"Nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"Nationality","label":"Nationality","error":failed ? 'The Nationality field is required' : null,"hasSuccess":passed,"placeholder":"Nationality"},model:{value:(_vm.nationality),callback:function ($$v) {_vm.nationality=$$v},expression:"nationality"}})]}}])})],1)])],1),_c('div',[_c('div',[_c('img',{staticStyle:{"width":"80%"},attrs:{"src":"static/img/upload_photo.png","alt":"crash"}})]),_c('br'),_c('div',[_c('input',{attrs:{"type":"file","accept":"image/*","id":"recfile","name":"recfile"},on:{"change":function($event){return _vm.uploadImage($event)}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }